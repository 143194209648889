$hiringUpsellBreakpoint: 750px;

.hiring-upsell {
  background: $yellow;
  padding: 20px !important;

  .hiring-upsell__inner,
  .hiring-upsell__inner-homepage {
    border: 10px $black solid;
    width: 100%;
    height: 100%;
    padding: 28px 28px 56px;

    .hiring-upsell__inner-content {
      display: flex;
      align-items: center;

      @media (max-width: $hiringUpsellBreakpoint) {
        flex-direction: column;
        align-items: inherit;
      }
    }
  }

  .hiring-upsell__inner-homepage {
    @media (max-width: $hiringUpsellBreakpoint) {
      border: none;
      padding: 12px 12px 56px;
    }
  }
}

.hiring-upsell__banner {
  background: $yellow;
  border: 8px $black solid;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @media (max-width: $hiringUpsellBreakpoint) {
    flex-direction: column;
    align-items: inherit;
  }
}

.hiring-upsell__banner-left {
  display: flex;
  align-items: center;
  flex: 2;
}

.hiring-upsell__banner-contact {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1;
  align-items: center;

  @media (max-width: $hiringUpsellBreakpoint) {
    margin-top: 16px;
  }
}

.hiring-upsell__small {
  position: relative;
  background: $yellow;
  border: 4px $black solid;
  padding: 4px 4px 4px 55px;
  font-size: 14px;

  .hiring-upsell__small-emoji {
    position: absolute;
    bottom: 0;
    left: -13px;
  }
}

.hiring-upsell__login-emoji {
  @media (max-width: $hiringUpsellBreakpoint) {
    display: none;
  }
}

.hiring-upsell__emoji {
  align-self: flex-start;
  width: 120px;
  height: 120px;

  @media (max-width: $hiringUpsellBreakpoint) {
    width: 80px;
    height: 80px;
  }
}

.hiring-upsell__description-web {
  display: block;

  @media (max-width: $hiringUpsellBreakpoint) {
    display: none;
  }
}

.hiring-upsell__description-mobile {
  display: none;

  @media (max-width: $hiringUpsellBreakpoint) {
    display: block;
    margin-top: 6px !important;
  }
}
