.pill--sng {
  background: $sngBg !important;
  color: $white !important;

  &:hover {
    background: $black !important;
    color: $white !important;
  }
}

.pill--sng-support {
  background: $sngSupportBg !important;
  color: $black !important;

  &:hover {
    background: $black !important;
    color: $white !important;
  }
}

.sng-microsite__header {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: -270px;
    display: block;
    width: 850px;
    height: 100%;
    background: url("/images/sng/drawing.png") no-repeat #fc3622;
    background-size: cover;
    background-position: top 0 right 0;

    @media (max-width: 1050px) {
      background: $primary;
    }
  }
}

.sng-microsite__container {
  padding-right: 40%;

  @media (max-width: 1150px) {
    padding-right: 0;
  }
}
