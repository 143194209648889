.search {
  padding: 20px 30px 5px;

  @media (max-width: 880px) {
    padding: 20px 20px 5px;
  }
}

.search__container {
  width: 100%;
  margin: 0 auto;
}

.search__input-container {
  position: relative;
  width: 100%;
}

.search__input-reset-button {
  position: absolute;
  top: 18px;
  right: 15px;
  color: $grayLight;
  overflow: hidden;
  display: flex;
  align-items: center;
  height: 24px;
  cursor: pointer;

  @media (max-width: $mobileBreakpoint) {
    top: 13px;
  }

  &:hover {
    a {
      text-decoration: none;
    }
  }

  a {
    color: $black;
    white-space: nowrap;
  }

  .filter__reset-label-web {
    display: block;

    @media (max-width: $mobileBreakpoint) {
      display: none;
    }
  }

  .filter__reset-label-mobile {
    display: none;

    @media (max-width: $mobileBreakpoint) {
      display: block;
    }
  }
}

.search__input-icon {
  position: absolute;
  top: 18px;
  left: 15px;
  color: $grayLight;
  width: 24px;

  @media (max-width: $mobileBreakpoint) {
    top: 13px;
    left: 10px;
  }

  svg { width: 100%; }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.search__loading {
  position: absolute;
  display: inline-block;
  top: 16px;
  right: 20px;
  animation: rotate 1500ms infinite linear;

  @media (max-width: $mobileBreakpoint) {
    top: 16px;
    right: 12px;
    font-size: 10px;
  }
}

.search__error {
  position: absolute;
  display: inline-block;
  top: 16px;
  right: 18px;
  color: $primary;

  @media (max-width: $mobileBreakpoint) {
    top: 16px;
    right: 12px;
    font-size: 11px;

    span { display: none; }
  }
}

.search__input-reset {
  padding-right: 135px !important;

  @media (max-width: $mobileBreakpoint) {
    padding-right: 90px !important;
  }
}

.search__input {
  width: 100%;
  height: 60px;
  border: 4px solid $black;
  padding: 18px 24px 18px 50px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  outline: none;
  color: $black;
  background: $white;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    height: 50px;
    padding: 0 30px 0 34px;
  }

  &::placeholder {
    color: $grayLight;
    opacity: 1;
  }
}

.search__results {
  z-index: 80;
  position: absolute;
  top: 62px;
  left: 0;
  right: 0;
  border: 4px solid $black;
  background: $white;
  color: $black;
  padding: 12px 0;
  max-height: 400px;
  overflow: scroll;
}

.search__results-empty {
  color: $black;
  padding: 0 27px;
}

.search__result-wrapper {
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }
}

.search__result-heading {
  position: relative;
  padding: 0 27px;
  text-transform: capitalize;
  color: $grayLight;
  font-size: 16px;
  line-height: 30px;
  font-weight: 300;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.search__result-heading-loading {
  position: relative;
  top: 1px;
  display: inline-block;
  margin-left: 10px;
  animation: rotate 1500ms infinite linear;
}

.search__result-item {
  display: block;
  padding: 0 27px;
  line-height: 30px;
  font-size: 16px;
  font-weight: 500;
  color: $black;
  text-decoration: none;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  &.search__result-item--active,
  &:hover {
    cursor: pointer;
    text-decoration: underline;
    background: $grayLightest;
  }
}
