.last-filter-settings {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  &.container {
    @media (max-width: 800px) {
      padding: 0 20px;
    }
  }
}

.last-filter-settings__title {
  margin-right: 8px;
  flex-shrink: 0;
}

.last-filter-settings__title--desktop {
  display: block;

  @media (max-width: 500px) {
    display: none;
  }
}

.last-filter-settings__title--mobile {
  display: none;

  @media (max-width: 500px) {
    display: block;
  }
}

.last-filter-settings__items {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.last-filter-settings__items-handler {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  text-decoration: underline;
  cursor: pointer;
}

.last-filter-settings__items-handler:hover {
  text-decoration: none;
}

.last-filter-settings__new-label {
  flex-shrink: 0;
  cursor: pointer;
}

.last-filter-settings__new-label .pill {
  margin-bottom: 0;
}

.last-filter-settings-item {
  flex-shrink: 0;
}

.last-filter-settings-item::after {
  content: (",  ");

  white-space: pre;
}

.last-filter-settings-item:last-of-type::after {
  content: ("");

  margin-right: 10px;
}
