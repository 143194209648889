.note-box {
  margin-bottom: 60px;

  &.margin-40 {
    margin: 40px 0;

    @media (max-width: $mobileBreakpoint) {
      margin: 24px 0;
    }
  }
}

.note-box--hide-mobile {
  @media (max-width: 700px) {
    display: none;
  }
}

.note-box__inner {
  background: $yellow;
  padding: 25px 30px;

  @media (max-width: $mobileBreakpoint) {
    padding: 16px 20px;
  }
}

.note-box__inner--big {
  padding: 50px 30px;
}

.note-box__inner--dark {
  background: $black;
  color: $white;

  .note-box__text a {
    color: $white;
  }
}

.note-box__inner--orange {
  background: $primary;
  color: black;

  .note-box__text a {
    color: black;
  }
}

.note-box__inner--yellow-bordered {
  background: $yellow;
  border: 6px $black solid;

  .note-box__text a {
    color: black;
  }
}

.note-box__inner--pink {
  background: $pink;
}

.note-box__inner--green {
  background: $greenPrimary;
}

.note-box__inner--yellow-soft {
  background: $yellowSoft;
}

.note-box__inner--purple-soft {
  background: #e9e0f5;
}

.note-box__icon {
  position: absolute;
  top: 50%;
  font-size: 48px;
  left: 0;
  margin-top: -24px;

  @media (max-width: 600px) {
    display: none !important;
  }
}

.note-box__heading,
.note-box__text {
  display: inline-block;
  position: relative;
  line-height: 1.6;
  margin: 0 auto;
  padding: 0 65px;
  font-size: 16px;
  font-weight: 300;

  @media (max-width: 600px) {
    padding: 0;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  a {
    color: $black;
    font-weight: 300;
  }

  .pill {
    position: relative;
    top: -1px;
  }
}

.note-box__heading {
  padding: 0;
  font-size: 18px;
}

.note-box__inner .input-wrapper--checkbox {
  margin-top: 20px;
}

.note-box--package-upsell {
  @media (max-width: $mobileBreakpoint) {
    .note-box__headline { display: none; }

    br {
      display: none;
    }
  }
}
