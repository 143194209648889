$shadowOffset: 10px;

.header {
  position: relative;
  z-index: 101;
  display: block;
  background: $black;
  height: 80px;
  width: 100%;

  @media (max-width: $headerTo) {
    height: 60px;
  }
}

.header__button {
  display: block;
  float: right;
  position: relative;
  background: none;
  outline: none;
  user-select: none;
  appearance: none;
  height: 80px;
  padding-left: 10px;
}

.header__button:not(.modal__header-close) {
  @media (max-width: 610px) {
    display: none;
  }
}

.header__button .button__in {
  display: block;
  float: right;
  position: relative;
  background: $primary;
  font-size: 16px;
  font-weight: 500;
  color: $black;
  text-decoration: none;
  padding: 16px 40px;
  outline: none;
  user-select: none;
  appearance: none;
  top: 11px;
  right: 10px;

  @media (max-width: 1070px) {
    padding: 16px 20px;
  }

  @media (max-width: 470px) {
    top: 0;
    right: 0;
    font-size: 30px;
    padding: 0 31px;
    line-height: 80px;

    span {
      display: none;
    }
  }

  &.black {
    background: $black;
    color: $white;
  }
}

.header__button:hover .button__in {
  top: 24px;
  right: 0;
  box-shadow: none;
  cursor: pointer;

  @media (max-width: 700px) {
    top: 11px;
    right: 10px;
  }

  @media (max-width: 470px) {
    top: 0;
    right: 0;
  }
}

.header__logo-wrapper {
  position: relative;
  width: 185px;
  height: 44px;
  float: left;
  display: block;
  margin-top: 17px;
  margin-left: 15px;
  line-height: 0;
  font-size: 0;
  color: transparent;
  cursor: pointer;

  @media (max-width: $headerTo) {
    margin-top: 8px;
  }
}

.header__logo {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 185px;
  height: 44px;
}

.header__logo--visible {
  opacity: 1;
}

.header__logo-beta {
  font-family: "Poppins", sans-serif;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  background: $black;
  color: $greenPrimary;
  padding: 0 6px;
  z-index: 10;
  line-height: 17px;
  border: 1px solid $greenPrimary;
  position: absolute;
  top: -6px;
  right: -20px;
}

.header__text {
  float: left;
  display: block;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  line-height: 80px;
  margin-left: 18px;

  a {
    color: $white;

    &:hover {
      text-decoration: none;
    }
  }

  @media (max-width: $headerTo) {
    display: none;
  }
}

.header__text--orange {
  color: $primary;
}

.header__text .job-detail__pill {
  position: relative;
  top: -2px;
  margin-left: 10px;
}

.header__emoji {
  position: relative;
  top: 2px;
  font-size: 18px;
}

.header__company {
  float: right;
  user-select: none;
}

.header__company-emoji {
  position: relative;
  top: 3px;
  font-size: 24px;
  margin-right: 6px;

  &.header__company-emoji--mobile {
    display: none;

    @media (max-width: $headerTo) {
      display: inline-block;
    }
  }
}

.header__company-emoji--small {
  margin-right: 2px;
}

.header__company-link {
  user-select: none;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  margin-right: 20px;
  line-height: 80px;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }

  @media (max-width: 400px) {
    margin-right: 14px;
  }
}

.header__company-login {
  height: 25px;
  position: relative;
  top: 30px;

  .header__company-link {
    position: relative;
    top: -12px;
    line-height: 18px;
  }

  .header__company-emoji {
    top: -9px;
  }

  .header__company-emoji.hover {
    display: none;
  }

  &:hover {
    .header__company-emoji.normal {
      display: none;
    }

    .header__company-emoji.hover {
      display: inline-block;
    }
  }
}

.header__company-link--desktop {
  @media (max-width: 400px) {
    display: none;
  }
}

.header__company-link--mobile {
  display: none;

  @media (max-width: 400px) {
    display: inline-block;
  }
}

.user-link--mobile {
  display: none;

  @media (max-width: 611px) {
    display: inline-block;
    text-decoration: underline;
  }
}

.header__title-xmas-emoji {
  line-height: 78px;
  font-size: 18px;
}

.modal-container-top {
  margin-top: 20px;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 30px;
  }
}

.header.header--green {
  background: $greenPrimary;
  color: $black;

  .header__text,
  .header__text a,
  .header__company-link {
    color: $black;
  }

  .dropdown__handler-white {
    .header__company-link {
      color: $white;
    }
  }

  .header__divider {
    background: $black;
  }
}

.header.header--orange {
  background: $primary;
  color: $black;

  .header__text,
  .header__text a,
  .header__company-link {
    color: $black;
  }
}

.header__menu-item {
  position: relative;
  margin-right: 20px;
  text-decoration: none;

  &:hover {
    text-decoration: underline !important;
  }
}

.header__menu-item--hidden {
  visibility: hidden;
}

.header__menu-item--active {
  text-decoration: underline !important;
}

.header__menu-item--nounderline {
  text-emphasis: none;

  .text {
    text-decoration: none;
  }

  &:hover {
    text-decoration: none;

    .text {
      text-decoration: underline;
    }
  }
}

.header__menu-item--with-badge {
  padding-right: 15px;
}

.header__menu-item-badge {
  background-color: $primary;
  padding: 0 5px;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  position: absolute;
  height: 16px;
  line-height: 16px;
  top: -10px;
  margin-left: -18px;
}

.header__menu-button {
  display: block;
  float: right;
  position: relative;
  background: none;
  outline: none;
  user-select: none;
  appearance: none;
  height: 60px;
  width: 50px;
  cursor: pointer;
  margin-right: 10px;

  @media (min-width: $headerTo + 1px) {
    display: none;
  }

  @media (max-width: 700px) {
    margin-right: 10px;
  }
}

.header__menu-button--open {
  background: url("/images/icons/icon-menu-white.svg") center center no-repeat;
  background-size: 21px 20px;
}

.header__menu-button--close {
  background: url("/images/icons/icon-close-white.svg") center center no-repeat;
  background-size: 19px 20px;
}

.header__right-desktop {
  @media (max-width: $headerTo) {
    display: none;
  }
}

.header__mobile-menu {
  visibility: hidden;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: $black;
  padding: 10px 30px;
  padding-top: 60px;
  -webkit-overflow-scrolling: touch;
  overflow-y: scroll;

  .header__menu-item-pill {
    background: white;
    color: black;
    top: -3px;
  }

  @media (min-height: 480px) {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  @media (min-width: $headerTo + 1px) {
    display: none;
  }

  .header__menu-item {
    display: block;
  }

  .header__menu-item-badge {
    top: 0;
  }

  .header__menu-item,
  .header__company-link {
    color: $white;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
  }

  .header__company {
    display: block;
    float: none;
    border-top: 1px dashed $white;
    padding-top: 10px;
  }

  .header__button {
    float: none;
    display: block !important;
    padding-left: 0;
    text-decoration: none;
    margin-top: 18px;
    height: 70px;
  }

  .header__button .button__in {
    float: none;
    top: 0;
    left: 0;
    width: 100%;
    text-align: center;
    font-size: 18px;
    line-height: 27px;
    padding: 16px 40px;
    background: $primary;
    color: $black;

    @media (max-width: $mobileBreakpoint) {
      padding: 12px 30px;
      font-size: 16px;
    }

    span {
      display: inline;
    }
  }

  .header__mobile-controls {
    display: flex;
    flex-direction: column-reverse;
  }

  .header__company-link--mobile {
    display: none;
  }

  .header__company-link--desktop {
    display: inline;
    color: $white;
  }
}

.header__mobile-menu--visible {
  visibility: visible;
}

.header--green {
  .header__menu-button--open {
    background: url("/images/icons/icon-menu-black.svg") center center no-repeat;
    background-size: 21px 20px;
  }

  .header__mobile-menu {
    .button__in {
      background: $greenPrimary;
    }
  }
}

.header__menu-item--responsive-hide-0 {
  @media (max-width: 1270px) {
    display: none;
  }
}

.header__menu-item--responsive-hide-1 {
  @media (max-width: 1190px) {
    display: none;
  }
}

.header__menu-item--responsive-hide-2 {
  @media (max-width: 930px) {
    display: none;
  }
}

.header__mobile-menu-heading {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header__mobile-menu-links {
  .job__info-link.job__info-link--profile {
    display: block;
    color: $white;
    font-size: 18px;
    line-height: 36px;
    font-weight: 500;
  }

  hr {
    display: none;
  }
}

.header__button.header__button--favourite {
  position: relative;

  @media (max-width: 1140px) {
    display: none !important;
  }

  .button__in {
    position: relative;
    padding: 16px;
    color: $greenPrimary;
  }

  .pill {
    position: absolute;
    top: -7px;
    right: 7px;
  }

  svg {
    float: left;
  }
}

.header__divider {
  display: inline-block;
  position: relative;
  top: 8px;
  width: 1px;
  height: 30px;
  background: $white;
  margin-right: 20px;

  @media (max-width: 1140px) {
    display: none;
  }
}

.header__menu-item-pill {
  top: -2px;
  margin-right: 3px !important;
  position: relative;
}
