.ad-hp {
  background: $black;
  color: $white;
  background-position: center;
  width: 100%;
  padding-top: 40px;
  padding-bottom: 40px;

  .container {
    min-height: 100%;
  }

  .freelancers__pricing-wraper {
    min-height: 100%;
  }

  .heading-2-5 {
    text-transform: uppercase;
    color: $greenPrimary;
    margin-bottom: 20px;
  }

  // @media (max-width: 1442px) {
  //   background-position: top right;
  // }

  // @media (max-width: 880px) {
  //   background: $primary;
  //   padding: 0;
  //   margin: 0;
  //   height: auto;
  // }

  @media (max-width: 500px) {
    display: none;
  }
}

.ad-hp--top {
  padding-bottom: 100px;
  margin-bottom: -60px;

  &.ad-hp--without-fold {
    margin-bottom: 40px;
  }
}

.ad-hp__content {
  padding: 60px 100px;
  max-width: 670px;

  @media (max-width: 880px) {
    padding: 40px 20px;
  }
}
