.feedback {
  z-index: 100;
  position: fixed;
  top: 50%;
  right: -50px;
  transform: rotate(-90deg);
  background: $grayLightest;
  width: 150px;
  height: 50px;
  text-align: center;
  margin-top: -25px;

  p {
    font-size: 16px;
    margin: 0;
    line-height: 50px;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }
  }

  a {
    display: block;
  }

  @media (max-width: 700px) {
    display: none;
  }
}
