$primary: #ff431d;
$primaryRgba: rgba(255, 67, 29, 0.2);
$black: #000;
$grayDark: #333;
$grayLight: #666;
$grayLightest: #e9e9e9;
$graySuperLight: #f2f2f2;
$graySuperLightRgba: rgba(0, 0, 0, 0.1);
$grayLightRgba: rgba(128, 128, 128, 0.1);
$white: #fff;
$green: #6cca37;
$yellow: #ffd62b;
$yellowSoft: #fff2bf;
$pink: #fe7ea8;
$red: #f98282;
$greenPrimary: #34ff6d;
$greenDark: #0a3316;
$greenPrimaryRgba: rgba(52, 255, 109, 0.2);
$primaryGradient: linear-gradient(270deg, #ff431d 0%, #ffed56 100%);
$headerTo: 1140px;
$touchBreakpoint: 700px;
$mobileBreakpoint: 500px;
$smallMobileBreakpoint: 400px;
$sngBg: #ff421f;
$sngSupportBg: #03ff8d;
