.full-height-content {
  min-height: calc(100vh - 483px);
}

.full-height-content-with-back {
  min-height: calc(100vh - 465px);
}

.full-height-content-with-testimonial {
  min-height: calc(100vh - 846px);
}

.flex-wrapper {
  display: flex;

  @media (max-width: 1200px) {
    display: block;
  }
}

.full-gray-wrapper {
  background: $grayLightest;
}

.full-black-wrapper {
  background: $black;
  color: $white;

  h1,
  h2,
  h3,
  p {
    color: $white;
  }
}

.full-wrapper {
  padding-top: 120px;
  padding-bottom: 120px;

  @media (max-width: 880px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.full-wrapper-small {
  padding-top: 60px;
  padding-bottom: 60px;

  @media (max-width: 880px) {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}

.container {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 1200px;
  width: 100%;
  padding: 0 30px;

  @media (max-width: $mobileBreakpoint) {
    padding: 0 24px;
  }
}

.container.container--padding {
  padding-top: 60px;
  padding-bottom: 60px;
}

.container.container--padding-bottom-small {
  padding-bottom: 30px;
}

.container.container--padding-bottom {
  padding-bottom: 60px;
}

.container.container--no-padding {
  padding: 0;
}

.container.container--flex {
  display: flex;
}

.container--66 {
  width: 66.6666%;
  padding: 70px 130px;

  @media (max-width: 1200px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    padding: 70px 50px;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 50px 30px;
  }
}

.container--33 {
  width: 33.3333%;
  padding: 70px 30px;
  margin-right: initial;

  @media (max-width: 1200px) {
    width: 100%;
    padding: 70px 130px;
  }

  @media (max-width: 800px) {
    padding: 50px;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 50px 30px;
  }
}

.container--panel-big {
  width: 60%;

  @media (max-width: 1200px) {
    width: 100%;
  }
}

.container--panel-small {
  width: 40%;
  margin-right: initial;

  @media (max-width: 1200px) {
    width: 100%;
    margin-top: 30px;
  }
}

.container--black {
  background: $black;
  color: $white;
}

.container--orange {
  background: $primary;
}

.container--green {
  background: $greenPrimary;
}

.container--medium {
  max-width: 990px;
}

.container--small {
  max-width: 810px;
}

.container--gray-lightest {
  background: $grayLightest;
}

.heading-wrapper {
  background: $grayLightest;
  padding: 60px 0;
  text-align: center;

  @media (max-width: $mobileBreakpoint) {
    padding: 30px 0;
  }
}

.heading-wrapper--align-left {
  text-align: left;
}

.heading-wrapper--orange {
  background: $primary;
}

.heading-wrapper--orange-soft {
  background: $primaryRgba;
}

.heading-wrapper--green-soft {
  background: $greenPrimaryRgba;
}

.heading-wrapper--half-gray {
  position: relative;
  background: transparent;

  &::before {
    z-index: -1;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 600px;
    background: $grayLightest;
  }

  @media (max-width: 800px) {
    background: $grayLightest;
    margin-bottom: 60px;

    &::before {
      display: none;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    margin-bottom: 50px;
  }
}

.flex {
  display: flex;
}

.justify-content-center {
  justify-content: center;
}

.grid-box {
  padding: 70px 20px;

  @media (max-width: 800px) {
    flex-direction: column;
    padding: 20px;
  }
}

.grid-box-raw {
  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.grid-box--small-padding {
  padding: 40px 20px;
}

.grid-item-50 {
  width: 50%;
  padding: 20px;

  &:nth-child(1) {
    padding-left: 0;
  }

  &:nth-child(2) {
    padding-right: 0;
  }

  @media (max-width: 800px) {
    width: 100%;
    padding: 20px 0;
  }
}

.grid-banner-container-banner {
  @media (min-width: 1800px) {
    display: flex;
    width: 100%;
    max-width: 1800px;
    margin: 0 auto;

    .content {
      flex: 1;
    }
  }
}
