$shadowOffset: 10px;

.button {
  position: relative;
  display: inline-block;
  outline: none;
  user-select: none;
  appearance: none;
  border: none;
  background: none;
  padding: 0;
  margin-bottom: 10px;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    text-align: center;
  }
}

.button .button__content {
  font-family: "Poppins", sans-serif;
  position: relative;
  display: inline-block;
  background: $primary;
  font-size: 16px;
  font-weight: 500;
  color: $black;
  text-decoration: none;
  padding: 15px 40px;
  border: 4px solid $black;
  box-shadow: $shadowOffset $shadowOffset $black;
  outline: none;
  user-select: none;
  appearance: none;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    font-size: 15px;

    // padding: 9px 30px;
  }
}

.button--full {
  width: 100%;
  text-align: center;

  .button__content {
    width: 100%;
  }
}

.button-simple {
  margin-bottom: 0;

  .button__content {
    box-shadow: none;
    border: none;
  }

  @media (max-width: $mobileBreakpoint) {
    .button__content {
      box-shadow: none !important;
    }
  }
}

.button:disabled .button__content {
  cursor: not-allowed !important;
}

.button:hover:not(:disabled):not(.button-no-hover) {
  cursor: pointer;
}

.button:hover:not(:disabled):not(.button-no-hover) .button__content {
  top: $shadowOffset;
  left: $shadowOffset;
  box-shadow: none;
  cursor: pointer;

  @media (max-width: 700px) {
    top: initial;
    left: initial;
    box-shadow: $shadowOffset $shadowOffset $black;
  }
}

.button--heading {
  float: right;
  padding: 0 5px;
  line-height: 30px;
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.button__content-loader {
  display: none;
}

.button--loading,
.button--loading:disabled {
  .button__content {
    position: relative;

    // background: $primary;
  }

  .button__content--percentage {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 0%;
    max-width: 100%;
    height: 100%;
    background: #d23414;
    transition: all 80ms linear;
  }

  .button__content-text {
    opacity: 0;
  }

  .button__content-loader-wrapper {
    position: absolute;
    left: 0;
    top: 13px;
    display: inline-block;
    width: 100%;
    text-align: center;

    @media (max-width: $mobileBreakpoint) {
      top: 7px;
    }
  }

  .button__content--rotate {
    display: inline-block;
    animation: rotate 1s infinite linear;
  }

  .button__content-loader {
    position: absolute;
    top: 13px;
    left: 0;
    width: 100%;
    text-align: center;

    @media (max-width: $mobileBreakpoint) {
      top: 7px;
    }
  }
}

.button.button--green .button__content {
  background: $greenPrimary;
}

.button.button--white .button__content {
  background: $white;
}

.button.button--black .button__content {
  background: $black;
  color: $white;
}

.button--gray .button__content {
  background: $grayLightest;
  color: $grayLight;
}

.button--desktop-content {
  @media (max-width: $mobileBreakpoint) {
    display: none !important;
  }
}

.button--mobile-content {
  @media (min-width: 501px) {
    display: none !important;
  }
}
