.job-detail__flex-wrapper {
  display: flex;
  position: relative;
  z-index: 1;

  @media (max-width: 880px) {
    flex-direction: column;
  }

  @media (max-width: $mobileBreakpoint) {
    &.margin-top {
      margin-top: 40px;
    }
  }
}

.job-detail__flex-wrapper--head {
  @media (max-width: 880px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.job-detail__heading-1 {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  line-height: 1.25;
  font-weight: 300;
  color: $grayLight;
  margin-top: 20px;
  margin-bottom: 5px;
  padding-right: 30px;

  @media (max-width: 880px) {
    padding-right: 0;
    margin-top: 0;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 18px;
    margin-bottom: 0;
  }

  a {
    color: $grayLight;

    &:hover {
      text-decoration: none;
    }
  }

  &.no-margin-top {
    margin-top: 0;
  }
}

.job-detail__heading-2 {
  font-family: "Poppins", sans-serif;
  font-size: 36px;
  line-height: 1.25;
  font-weight: 500;
  color: $black;
  padding-right: 30px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 18px;
  }
}

a.job-detail__heading-2 {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.job-detail__header-left {
  flex: 1;

  @media (max-width: 880px) {
    .pill {
      display: none;
    }
  }

  // @media (max-width: $mobileBreakpoint) {
  //   flex: 0 0 100%;
  // }
}

.job-detail__header-left--mobile-center {
  @media (max-width: 880px) {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }
}

.job-detail-company__header-left {
  flex: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;

  @media (max-width: $mobileBreakpoint) {
    flex: 0 0 100%;
  }
}

.job-detail__header-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 280px;
  padding-bottom: 7px;

  @media (max-width: 880px) {
    width: 100%;
    margin-top: 30px;
    padding-bottom: 0;
  }

  @media (max-width: $mobileBreakpoint) {
    margin-top: 20px;
  }
}

.job-detail__content-left {
  flex: 1;
  padding-right: 160px;
  padding-top: 20px;

  @media (max-width: 880px) {
    padding-right: 0;
  }
}

.job-detail__content-left .margin-top:first-child {
  margin-top: 0;
}

.job-detail__content-right {
  width: 280px;

  @media (max-width: 880px) {
    margin: 0 auto;
    margin-top: 60px;

    &.job-detail__content-right--company {
      display: none;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    margin-top: 0;
    padding-top: 0;
  }

  &.job-detail__content-right--with-sticky-mobile-content {
    .profile--gray-box {
      margin-left: 0;
      margin-right: 0;
      padding: 12px 20px;
    }

    @media (max-width: 880px) {
      margin-top: 0;
      width: 100%;

      .sticky {
        position: relative;
      }
    }
  }

  .job-detail__content-right-banner {
    @media (max-width: 880px) {
      display: none;
    }
  }
}

.job-detail__button-mobile-hide {
  @media (max-width: 880px) {
    display: none;
  }
}

.job-detail__info-box {
  border-top: 1px dotted $black;
  margin-top: 25px;

  a {
    color: $black;
  }
}

.job-detail__header-left-logo {
  flex: 0 0 180px;
  align-self: flex-start;

  @media (max-width: 880px) {
    flex: 0 0 100px;
  }

  @media (max-width: $mobileBreakpoint) {
    flex: 0 0 60px;
    margin-bottom: 20px;
  }
}

.job-detail__logo-wrapper {
  display: flex;
  align-items: center;
  width: 180px;
  height: 180px;
  text-align: center;
  margin-right: 40px;

  @media (max-width: 880px) {
    width: 100px;
    height: 100px;
    margin-right: 20px;
  }

  @media (max-width: $mobileBreakpoint) {
    width: 60px;
    height: 60px;
  }

  &.company {
    cursor: pointer;
  }
}

.job-detail__logo-wrapper--clickable {
  cursor: pointer;
}

.job-detail__logo {
  width: 100%;
  height: auto;
}

.job-detail__logo--rounded {
  border-radius: 50%;
}

.job-detail__sticky-element {
  display: flex;
  width: 100%;
  background: $black;
  color: $white;
  padding: 15px 0;
  font-weight: 300;

  span {
    color: #777;
  }

  strong {
    font-weight: 500;
  }

  @media (max-width: 600px) {
    .flex {
      display: block;

      .job-detail__sticky-element-right {
        text-align: left !important;
      }
    }
  }
}

.job-detail__sticky-element-right,
.job-detail__sticky-element-left {
  flex: 1;
  text-align: left;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    text-align: left;
    flex: none;
  }
}

.job-detail__sticky-element-right {
  text-align: right;
}

.job-detail__content-left ol {
  padding-left: 30px;
}

.job-detail__content-left ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 20px;
  margin-bottom: 48px;
}

.job-detail__content-left ul li {
  position: relative;
  padding-left: 15px;

  &::before {
    position: absolute;
    top: 3px;
    left: -8px;
    content: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f449.svg");
    display: inline-block;
    width: 18px;
  }

  p {
    display: inline;
  }
}

.job-detail__content-pills {
  margin-bottom: 20px;

  .pill--tag.pill--link {
    &.pill--dark-hoverable {
      color: #fff;
      background: #000;
    }

    &.pill--dark-hoverable:hover {
      color: #777;
      background: #e9e9e9;
    }
  }
}

.job-detail-add__content-left {
  position: relative;
  flex: 1;
  padding-right: 30px;
  padding-top: 20px;

  @media (max-width: 880px) {
    padding-right: 0;
  }
}

.job-detail-add__content-right {
  width: 360px;
}

.job-detail__content-right .upsell-line {
  display: block;
  margin-left: -24px;
  margin-right: -24px;
}

.job-detail__tablet-hidden {
  @media (max-width: 880px) {
    display: none;
  }
}

.job-detail__tablet-sticky {
  display: none;
  position: sticky;
  bottom: 0;
  left: 0;
  width: 100%;
  background: $white;
  padding: 24px 0;
  gap: 24px;
  z-index: 10;

  @media (max-width: 880px) {
    display: flex;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 16px 0;
  }

  .button {
    margin-bottom: 6px;
  }

  .button .button__content {
    padding: 9px 16px;
    box-shadow: 6px 6px 0 0 $black;
  }
}
