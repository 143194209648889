$bannerMobileBreakpoint: 700px;

.banner-freelance {
  position: relative;
  margin-top: 60px;
  padding: 100px 0;
  background: $grayLightest;

  @media (max-width: $bannerMobileBreakpoint) {
    margin: 0;
    padding: 60px 0;
  }
}

.banner-freelance-heading {
  font-family: "Rubik", sans-serif;
  color: $black;
  font-size: 58px;
  font-weight: 900;
  max-width: 950px;
  margin: 0 auto;

  @media (max-width: $bannerMobileBreakpoint) {
    font-size: 32px;
    line-height: 44px;
  }
}

@keyframes rotate {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

.banner-freelance__badge {
  position: absolute;
  top: 50%;
  margin-top: -100px;
  right: 0;
  transform: rotate(-330deg);
  color: $greenPrimary;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  width: 200px;
  height: 200px;
  border-radius: 50%;
  background: $black;
  line-height: 200px;
  animation: rotate 6s infinite linear;

  @media (max-width: 1220px) {
    display: none;
  }
}
