$jobSelectTabletBreakpoint: 1000px;

.job-select {
  height: auto;
  margin-top: 70px;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 40px;
  }
}

.job-select__wrapper {
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  user-select: none;
  position: relative;
  text-align: left;
  width: 100%;
  padding: 0;
  font-size: 15px;

  @media (max-width: $jobSelectTabletBreakpoint) {
    flex-flow: column;
  }
}

.job-select__caption {
  flex: 1;

  @media (max-width: $jobSelectTabletBreakpoint) {
    display: none;
  }
}

.job-select__item-carousel {
  position: absolute;
  top: -19px;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: hidden;

  @media (max-width: 1000px) {
    display: none;
  }
}

.job-select__item-carousel-inner {
  position: absolute;
  background: $black;
  width: 1260px;
  animation: text-slide2 50s linear infinite alternate;
}

.job-select__item-carousel-text {
  position: relative;
  top: -3px;
  display: inline-block;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  margin: 0 10px;
}

.job-select__item {
  position: relative;
  border-left: 1px dotted $black;
  flex: 0 0 275px;
  background: $white;

  &:nth-child(2) {
    border-left: none;
  }

  @media (max-width: $jobSelectTabletBreakpoint) {
    margin-bottom: 24px;
    border: none;
    flex: 1;
  }

  &.job-select__item--selected {
    .job-select__button--select {
      display: none;
    }

    .job-select__button--disabled {
      display: flex;
    }
  }

  &.job-select__item--disabled {
    cursor: not-allowed !important;

    p,
    li,
    span,
    div,
    .heading-3 {
      color: #777 !important;
    }

    .tooltip__button {
      display: none;
    }

    .job-select__section-select {
      visibility: hidden;
    }

    .job-select__item-main {
      background: $white !important;
      cursor: not-allowed !important;
    }

    .job-select__button {
      background: #777 !important;
      color: $white !important;
      cursor: not-allowed;

      a {
        color: $white !important;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.job-select__heading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px 14px;
  height: 115px;
  border-bottom: 1px dotted $black;

  &.job-select__heading--regular {
    align-items: flex-start;
    justify-content: flex-end;
  }

  .heading-3 {
    margin: 0;
  }
}

.job-select__heading-text {
  font-weight: 300;
}

.job-select__item-price {
  font-size: 28px;
  line-height: 30px;
  font-weight: 300;
  margin-top: 10px;
}

.job-select__section {
  border-bottom: 1px dotted $black;

  &.job-select__section-item--no-border {
    border-bottom: none;
  }

  @media (max-width: $jobSelectTabletBreakpoint) {
    border-bottom: none;

    &:nth-of-type(2) {
      padding-top: 7px;
    }

    &:nth-of-type(4) {
      padding-bottom: 7px;
    }
  }
}

.job-select__section-item {
  border-bottom: 1px dotted $graySuperLightRgba;
  height: 50px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 0 14px;
  font-weight: 300;

  svg {
    width: 24px;
    height: 24px;
  }

  strong {
    font-weight: 500;
  }

  &:last-child {
    border-bottom: none;
  }

  a {
    font-weight: 500;
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }

  &.job-select__section-item--long {
    padding-top: 14px;
    height: auto;
  }

  @media (max-width: $jobSelectTabletBreakpoint) {
    height: auto;
    border-bottom: none;
    text-align: left;
    display: block;
    position: relative;
    padding: 6px 14px;
    padding-left: 46px;

    span:not(.job-select__section-mobile--without-margin) {
      display: inline-block;
      margin-right: 4px;
    }

    svg {
      position: absolute;
      top: 50%;
      left: 14px;
      margin-top: -12px;
    }

    &:not(.job-select__section-item--included) {
      display: none;
    }
  }
}

.job-select__section-select {
  position: relative;
  padding: 14px;

  @media (max-width: $jobSelectTabletBreakpoint) {
    border-top: 1px dotted $black;
  }
}

.job-select__section-mobile,
svg.job-select__section-mobile {
  display: none;

  @media (max-width: $jobSelectTabletBreakpoint) {
    display: inline-block;
  }
}

.job-select__section-mobile-strong {
  @media (max-width: $jobSelectTabletBreakpoint) {
    font-weight: 500;
  }
}

.job-select__caption {
  .job-select__section-item {
    justify-content: flex-start;
    font-weight: 300;
    text-align: left;

    strong {
      font-weight: 500;
    }
  }
}

.job-select__item-main {
  flex-grow: 1;
  background: #fff;
}

.job-select__button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  background: $primary;
  cursor: pointer;
  padding: 0 14px;
  height: 60px;
  user-select: none;
  text-decoration: none;
  color: $black !important;

  &:hover {
    background: $black;
    color: $white !important;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.job-select__button--disabled {
  display: none;
  background: $black !important;
  color: $white !important;
  cursor: default;
}

.job-select__button--disabled-visible {
  background: $black !important;
  color: $white !important;
  cursor: not-allowed;
}

.job-select__checkbox {
  label::before {
    position: absolute;
    top: -1px;
  }
}

.job-select__checkbox-text {
  display: flex;
  font-size: 15px;
  font-weight: 500;
  margin-left: 40px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $jobSelectTabletBreakpoint) {
    font-size: 15px;

    span:not(.tooltip__button) {
      top: 0 !important;
    }

    & > span {
      top: -2px !important;
    }
  }

  .pill {
    position: relative;
    top: -2px !important;
    margin-right: 6px;
  }

  .pill--mobile-only {
    display: none;

    @media (max-width: $jobSelectTabletBreakpoint) {
      display: inline-block;
    }
  }
}

.job-select__checkbox-text-small {
  font-size: 14px;
}

.job-select__checkbox-text-crossed {
  text-decoration: line-through;
  color: $primary;
  font-weight: 300;
}
