.skill-level-select .checkboxes-items {
  @media (min-width: 700px) {
    display: grid;
    grid-auto-flow: column;
    grid-template-rows: 1fr 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;

    .input-wrapper {
      width: inherit;
    }
  }
}
