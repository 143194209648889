// FONTS
// font-family: 'Poppins', sans-serif;
// font-weight: 300 / 500 / 700
// --
// font-family: 'Rubik', sans-serif;
// font-weight: 900;

.heading-ultra {
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  color: $black;
  font-size: 144px;
  line-height: 166px;
  margin-bottom: 15px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 72px;
    line-height: 86px;
  }
}

.heading-1 {
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  color: $black;
  font-size: 48px;
  line-height: 1.5;
  margin-bottom: 15px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 28px;
    line-height: 30px;
  }
}

.heading-1--white {
  color: $white;
}

.heading-2 {
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  color: $black;
  font-size: 36px;
  line-height: 50px;
  margin-bottom: 15px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 28px;
    line-height: 30px;
  }
}

.heading-2-5 {
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  color: $black;
  font-size: 30px;
  line-height: 1.2;
  margin-bottom: 15px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 24px;
    line-height: 1.3;
  }

  .uppercase {
    text-transform: uppercase;
  }

  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }
}

.heading-3 {
  font-family: "Rubik", sans-serif;
  font-weight: 900;
  color: $black;
  font-size: 24px;
  line-height: 32px;
  margin-bottom: 15px;
  text-transform: uppercase;

  @media (max-width: $mobileBreakpoint) {
    font-size: 21px;
    line-height: 26px;
  }

  .uppercase {
    text-transform: uppercase;
  }
}

.heading-4 {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  color: $black;
  font-size: 19px;
  line-height: 1.6;
  text-transform: uppercase;
  margin-bottom: 6px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 18px;
  }
}

a.heading-3 {
  display: block;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.job-detail__content-left p,
.paragraph,
li {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.7;
  margin-bottom: 15px;
  word-break: break-word;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.6;
  }

  &.margin-top {
    @media (max-width: $mobileBreakpoint) {
      margin-top: 24px;
    }
  }

  .pill.pill--super-small {
    position: relative;
    top: -3px;
  }
}

.paragraph--margin-left {
  margin-left: 20px;
}

.paragraph--margin-small {
  margin-bottom: 8px;
}

.paragraph--no-margin {
  margin-bottom: 0;
}

.paragraph.paragraph--big {
  font-size: 20px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 18px;
    line-height: 1.6;
  }
}

.paragraph.paragraph--white {
  color: $white;
}

.paragraph--orange,
.paragraph.paragraph--orange {
  color: $primary;
}

.paragraph.paragraph--gray {
  color: $grayLight;
}

.paragraph.paragraph--mobile-lineheight {
  line-height: 40px !important;
}

.paragraph.paragraph--small {
  font-size: 16px;
  line-height: 1.5;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.6;
  }
}

.paragraph.paragraph--super-small {
  font-size: 14px;
  line-height: 21px;
}

.paragraph.paragraph--extra-small {
  font-size: 13px;
  line-height: 19px;
}

li strong,
.paragraph strong,
.job-detail__content-left p strong,
.job-detail__content-left p.paragraph--bold,
.paragraph--bold {
  font-weight: 500;
}

.paragraph--light {
  font-weight: 300;
}

li a,
.label a,
.paragraph a,
.job-detail__content-left a {
  color: $primary;
  font-weight: 500;
  word-break: break-word;

  &.link--black {
    color: $black;
  }
}

li a:hover,
.paragraph a:hover,
.job-detail__content-left a:hover {
  text-decoration: none;
}

.emoji {
  font-size: 30px;
  margin-bottom: 40px;
}

.paragraph a.link--black,
.link--black {
  color: $black;
  text-decoration: underline;
  cursor: pointer;

  strong {
    font-weight: 500;
  }

  &:hover {
    text-decoration: none;
  }
}

.paragraph a.link--white,
.link--white {
  color: $white;
  text-decoration: underline;
  cursor: pointer;

  strong {
    font-weight: 500;
  }

  &:hover {
    text-decoration: none;
  }
}

.paragraph.with-icon {
  svg {
    font-size: 24px;
    position: relative;
    top: 6px;
    margin-right: 5px;
    margin-top: -6px;
    width: 24px;
    height: 24px;
  }
}

.strike {
  text-decoration: line-through;
}

.link {
  text-decoration: underline;
  cursor: pointer;

  &:hover {
    text-decoration: none;
  }
}

.underline,
strong.underline {
  text-decoration: underline;
}

.line-through {
  text-decoration: line-through;
}

.text-green-primary {
  color: $greenPrimary !important;
}
