.summary {
  margin-top: 0;
}

.payment__summary,
.job-detail-add__summary {
  @media (max-width: 880px) {
    width: 100%;
  }
}

.summary__upsell {
  background: $grayLightest;
}

.summary__upsell-content {
  position: relative;
  padding: 16px 18px;

  @media (max-height: 770px) {
    padding: 10px;
  }
}

.summary__upsell-content-active-info {
  background: $yellow !important;
  font-size: 14px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.summary__price {
  display: flex;
  justify-content: space-between;
  background: $black;
  padding: 8px 18px;
  color: $white;
  align-items: center;
  margin-bottom: 16px;
}

.summary__price--without-bottom-margin {
  margin-bottom: 0;
}

.summary__price-title {
  font-size: 22px;
  font-weight: 300;
}

.summary__price-text {
  font-size: 16px;
  font-weight: 300;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.summary__price-value {
  font-size: 30px;
  font-weight: 300;
}

.summary__payment {
  background: $graySuperLight;
  padding: 14px 20px;
  border-bottom: 1px dotted $black;
}

.summary__payment--without-bottom-border {
  border-bottom: none;
}

.summary__payment-title {
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 24px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.4;
  }
}

.summary__payment-line label {
  font-size: 16px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.4;
  }
}

.summary__payment-card-logo {
  display: inline-block;
  position: absolute;
  background: url("/images/card.svg") center center no-repeat;
  top: 0;
  width: 34px;
  height: 36px;
  background-size: cover;

  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}

.summary__payment-text {
  font-weight: 500;
  font-size: 16px;
  line-height: 1.2;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.4;
  }

  .pill {
    top: -2px !important;
  }
}

.summary__payment-text--light {
  color: $grayLight;
  font-weight: 300;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.4;
  }
}

.summary__payment-checkbox-label {
  display: inline-block;
  position: relative;
  top: -4px;

  @media (max-width: $mobileBreakpoint) {
    .pill {
      display: none;
    }
  }
}

.summary__payment-checkbox-label .pill {
  top: 0;
  margin-left: 6px;
}

.summary__payment-text-offset {
  margin-left: 45px;

  @media (max-width: $mobileBreakpoint) {
    margin-left: 0;
  }
}

.summary__payment-line {
  margin-bottom: -10px;
}

.summary__payment-line .styled-checkbox + label::before {
  top: -18px;
}

.summary__bill {
  background: $black;
  padding: 8px 20px;
  color: $white;
  border-bottom: 1px dotted $white;

  @media (max-height: 680px) {
    display: none;
  }
}

.summary__bill-line {
  display: flex;
  justify-content: space-between;
}

.summary__checkbox-text {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  .pill {
    position: relative;
    top: -1px;
    line-height: 17px;
    margin-left: 0;
  }
}

.summary__upgrade {
  position: relative;
  display: flex;
  align-items: center;
  background: $grayLightest;
  padding: 12px 16px;
  margin-bottom: 16px;
  min-height: 60px;
  cursor: pointer;

  &:hover .paragraph {
    text-decoration: underline;
  }

  &.summary__upgrade--yellow {
    background: $yellow;
  }
}

a.summary__upgrade {
  text-decoration: none;
  color: $black;

  .summary__upgrade-text {
    text-decoration: underline;
  }

  &:hover {
    .summary__upgrade-text {
      text-decoration: none;
    }
  }
}

.summary__upgrade--without-margin {
  margin: 0;
}

.summary__upgrade-icon {
  position: absolute;
  top: 50%;
  left: 8px;
  margin-top: -23px;
  font-size: 30px;
}

.summary__upgrade-text {
  padding-left: 30px;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  margin-bottom: 0 !important;
}

.summary__upsell-content .show-more {
  display: inline-block;
  margin-right: 10px;
}

.summary__input-wrapper {
  position: relative;
  margin-top: 14px;

  .input-wrapper input:focus + .summary__input-button {
    color: $black;
  }

  .input {
    padding-right: 100px;
  }

  .input__error-text {
    right: 16px;
  }
}

.summary__input-button {
  position: absolute;
  top: 17px;
  right: 16px;
  color: $black;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  &:hover {
    text-decoration: none;
  }

  &.summary__input-button--white {
    color: $white;
  }
}

.summary__upgrade--hide-on-small-height {
  @media (max-height: 740px) {
    display: none;
  }
}

.summary__checkbox-text--promo {
  position: relative;
  top: -2px;
}

.summary__upgrade--checkbox {
  padding: 0 3px;

  .input-wrapper--selectbox {
    border: none;
  }

  .styled-checkbox--multiline + label > span {
    display: inline-block;
    font-size: 16px;
    font-weight: 500;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }
  }
}
