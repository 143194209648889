/* stylelint-disable */
.buttonBack___1mlaL,
.buttonFirst___2rhFr,
.buttonLast___2yuh0,
.buttonNext___2mOCa,
.buttonNext___3Lm3s,
.dot___3c3SI { cursor: pointer; }

.image___xtQGH {
  display: block;
  width: 100%;
  height: 100%;
}

.spinner___27VUp {
  position: absolute;
  top: calc(50% - 15px);
  left: calc(50% - 15px);
  width: 30px;
  height: 30px;
  animation-name: spin___S3UuE;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  border: 4px solid #a9a9a9;
  border-top-color: $black;
  border-radius: 30px;
}

@keyframes spin___S3UuE {
  0% { transform: rotate(0deg); }
  to { transform: rotate(1turn); }
}

.container___2O72F {
  position: relative;
  overflow: hidden;
  height: 100%;
  width: 100%;
}

.overlay___IV4qY {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  opacity: 0;
  cursor: zoom-in;
  transition: opacity 0.3s, transform 0.3s;
}

.hover___MYy31,
.loading___1pvNI,
.zoom___3kqYk { opacity: 1; }

.imageLoadingSpinnerContainer___3UIPD {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4;
}

.slide___3-Nqo {
  position: relative;
  display: block;
  box-sizing: border-box;
  height: 0;
  margin: 0;
  list-style-type: none;
}
.slide___3-Nqo:focus { outline: none !important; }
.slideHorizontal___1NzNV { float: left; }

[dir=rtl] .slideHorizontal___1NzNV {
  direction: rtl;
  transform: scaleX(-1);
}

.slideInner___2mfX9 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.focusRing___1airF {
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  pointer-events: none;
  outline-width: 5px;
  outline-style: solid;
  outline-color: Highlight;
}

@media (-webkit-min-device-pixel-ratio: 0) {
  .focusRing___1airF {
    outline-style: auto;
    outline-color: -webkit-focus-ring-color;
  }
}

.horizontalSlider___281Ls {
  position: relative;
  overflow: hidden;
}

[dir=rtl] .horizontalSlider___281Ls {
  direction: ltr;
  transform: scaleX(-1);
}

.horizontalSliderTray___1L-0W {
  overflow: hidden;
  width: 100%;
}

.verticalSlider___34ZFD {
  position: relative;
  overflow: hidden;
}
.verticalSliderTray___267D8 { overflow: hidden; }
.verticalTray___12Key { float: left; }
.verticalSlideTrayWrap___2nO7o { overflow: hidden; }

.sliderTray___-vHFQ {
  display: block;
  list-style: none;
  padding: 0;
  margin: 0;
}

.sliderAnimation___300FY {
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.645, 0.045, 0.355, 1);
  will-change: transform;
}

.masterSpinnerContainer___1Z6hB {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: #f4f4f4;
}

.carousel {
  position: relative;
}

.carousel__text {
  font-family: 'Poppins', sans-serif;
  font-size: 21px;
  font-weight: 300;
  line-height: 36px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 18px;
    line-height: 1.7;
  }
}

.carousel__slider {
  height: 460px;

  @media (max-width: $mobileBreakpoint) {
    height: 500px;
  }
}

.carousel__inner-slide {
  height: 460px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: $mobileBreakpoint) {
    height: 500px;
  }
}

.carousel__slide-content {
  padding: 90px 30px;
  margin: 0 auto;
  width: 100%;
  max-width: 600px;

  @media (max-width: $mobileBreakpoint) {
    padding: 40px 0;
  }
}

.carousel__author {
  display: flex;
  margin-top: 36px;
  align-items: center;

  .paragraph {
    font-weight: 500;
    line-height: 24px;
    margin: 0;
    margin-left: 20px;
  }

  @media (max-width: $mobileBreakpoint) {
    flex-direction: column;
    align-items: flex-start;

    .paragraph {
      margin-top: 16px;
    }
  }
}

.carousel__next-button,
.carousel__back-button {
  position: absolute;
  top: 50%;
  margin-top: -30px;
  width: 50px;
  height: 50px;
  background: transparent;
  border: none;
  border-radius: 0;
  cursor: pointer;
  outline: none !important;

  .button__in {
    display: block;
    float: right;
    position: relative;
    background: $grayLightest;
    color: $black;
    text-decoration: none;
    outline: none;
    user-select: none;
    appearance: none;
    top: 11px;
    right: 10px;
    width: 50px;
    height: 50px;
    padding: 0 19px;
    line-height: 52px;
    font-size: 16px;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }
  }

  &:hover {
    .button__in {
      top: 22px;
      right: 0;
    }
  }

  @media (max-width: 850px) {
    display: none;
  }
}

.carousel__back-button {
  left: 30px;
}

.carousel__next-button {
  right: 30px;
}

.carousel-grey {
  background: $grayLightest;

  .button__in {
    background: $white;
  }
}

.carousel--small-padding {
  .carousel__slider,
  .carousel__inner-slide {
    height: 360px;
  }

  .carousel__slide-content {
    padding: 60px 30px;
  }
}

.carousel-black {
  background: $black;
  color: $white;

  .button__in {
    background: $white;
  }
}

.carousel-white {
  background: $white;
  color: $black;

  .button__in {
    background: $grayLightest;
  }

  .carousel-big__author-name a {
    color: $black;
  }
}

.carousel-big__fold {
  padding-bottom: 80px;
  margin-bottom: -80px;
}

.carousel-big {
  .carousel__slider {
    height: 320px;

    @media (max-width: 700px) {
      display: none;
    }
  }

  .carousel__inner-slide {
    height: 320px;

    @media (max-width: 700px) {
      height: 280px;
    }
  }
}

.carousel-big__slide-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 100px;
  margin: 0 auto;
  width: 100%;

  @media (max-width: 700px) {
    padding: 0;
  }
}

.carousel-big__text-wrapper {
  max-width: 500px;

  @media (max-width: 990px) {
    margin: 0 auto;
  }
}

.carousel-big__author {
  flex: 0 0 300px;
  width: 300px;
  height: 250px;

  img {
    width: 300px;
    height: 250px;
  }

  @media (max-width: 1100px) {
    flex: 0 0 200px;
    width: 200px;
    height: auto;

    img {
      width: 200px;
      height: auto;
    }
  }

  @media (max-width: 990px) {
    display: none;
  }
}

.carousel-big .carousel__next-button,
.carousel-big .carousel__back-button {
  width: 50px;
  height: 50px;
  padding: 0;
}

.carousel-big .carousel__back-button {
  .button__in {
    top: 0;
    left: 0;
  }

  &:hover {
    .button__in {
      top: 4px;
      left: -4px;
    }
  }
}

.carousel-big .carousel__next-button {
  .button__in {
    top: 0;
    right: 0;
  }

  &:hover {
    .button__in {
      top: 4px;
      right: -4px;
    }
  }
}

.carousel-big__text {
  font-family: 'Poppins', sans-serif;
  font-size: 22px;
  font-weight: 300;
  line-height: 1.4;

  @media (max-width: 700px) {
    font-size: 18px;
  }
}

.carousel-big__author-name {
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  margin-top: 25px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  a {
    color: $white;
  }
}

.carousel-big .back__icon {
  margin: 0;
  width: 6px;
  height: 10px;
}

.carousel-big .back__icon.back__icon--next {
  transform: rotate(180deg);
}

.carousel-big .modal__header-close {
  display: none;
  top: 12px;
  right: 30px;
  height: 50px;
  width: 50px;

  .button__in {
    top: 0;
    right: 0;
    padding: 0 12px;
    width: 50px !important;
    height: 50px;
    line-height: 50px;
    box-sizing: border-box;
  }

  &:hover {
    .button__in {
      top: 4px;
      right: -4px;
    }
  }
}

.carousel-big:hover .modal__header-close {
  display: block;
}
/* stylelint-enable */