.empty-state-container {
  @media (max-width: 880px) {
    padding: 0 20px !important;
  }
}

.empty-state {
  border: 1px dashed $black;
  padding: 50px 30px;
}
