@mixin thumb {
  height: 20px;
  width: 20px;
  background-color: $white;
  border: 3px solid $black;
  border-radius: 50%;
  transition: 0.2s ease-in-out;
}

.range-slider {
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 0;
  height: 4px;
  background: #777;

  &::-webkit-slider-thumb {
    appearance: none;

    @include thumb;
  }

  &::-moz-range-thumb {
    @include thumb;
  }
}
