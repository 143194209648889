.button-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 50px;
  width: 50px;
  height: 50px;
  background: $white;
  color: $grayLight;
  user-select: none;
  cursor: pointer;

  &.button-icon--default-style-white {
    background: $white;
    color: $grayLight;
  }

  &.button-icon--default-style-gray {
    background: $grayLightest;
    color: $black;
  }

  .icon-full {
    display: none;
  }

  &.button-icon--disabled {
    cursor: not-allowed;
  }

  @media (min-width: 881px) {
    &:hover:not(.button-icon--disabled) {
      background: $white;
      color: $black;
    }
  }

  &.button-icon--active {
    background: $black;
    color: $greenPrimary;

    @media (min-width: 881px) {
      &:hover:not(.button-icon--disabled) {
        background: $white;
        color: $black;
      }
    }
  }

  &:not(.button-icon--fixed-size) {
    @media (max-width: 880px) {
      flex: 0 0 30px;
      width: 30px;
      height: 30px;
    }
  }
}
