.companies {
  &::after {
    position: relative;
    top: -1px;
    content: "";
    width: 100%;
    height: 1px;
    background: $white;
  }
}

.company-item {
  overflow: hidden;
  display: flex;
  align-items: center;
  padding: 14px;
  flex: 0 0 33%;
  border-bottom: 1px dotted $black;
  border-right: 1px dotted $black;
  box-sizing: border-box;
  text-decoration: none;
  color: $black;

  &:nth-child(3n) {
    border-right: none;
  }

  &:hover {
    cursor: pointer;
    background: $grayLightRgba;
  }

  @media (max-width: 900px) {
    flex: 0 0 50%;

    &:nth-child(3n) {
      border-right: 1px dotted $black;
    }

    &:nth-child(2n) {
      border-right: none;
    }
  }

  @media (max-width: 550px) {
    flex: 0 0 100%;

    &:nth-child(3n) {
      border-right: 1px dotted $black;
    }

    &:nth-child(2n) {
      border-right: 1px dotted $black;
    }

    &:nth-child(1n) {
      border-right: none;
    }
  }
}

.company-item.company-item--single {
  border-right: none;
  padding-right: 8px;
}

.company__logo-wrapper {
  display: flex;
  align-items: center;
  flex: 0 0 80px;
  text-align: center;
  margin-right: 14px;
}

.company__logo {
  width: 80px;
  height: 80px;
}

.company-item .job__title,
.company-item .job__subtitle {
  font-size: 16px;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.company__content {
  overflow: hidden;
}

.company__banner {
  position: relative;
}

.company__banner-pill {
  margin-bottom: 10px;
}

.company__banner-heading {
  line-height: 1.1;

  .company__banner-heading-underline {
    position: relative;
    z-index: 2;

    &::before {
      z-index: -1;
      content: " ";
      position: absolute;
      left: 0;
      bottom: 6px;
      width: 100%;
      height: 8px;
      background: $white;
    }
  }

  .company__banner-heading-crossed {
    font-family: "Poppins", sans-serif;
    text-decoration: line-through;
    font-weight: 300;
  }
}

.company__banner-badge {
  top: 40px;
  right: 40px;
  background: $black;
  color: $white;
  text-align: center;

  &.company__banner-badge--yellow {
    background: $yellow;
    color: $black;
  }

  @media (max-width: 1700px) {
    right: 20px;
  }

  @media (max-width: 1600px) {
    width: 120px;
    height: 120px;
    display: flex;
    right: 20px;
    font-size: 16px;
  }

  @media (max-width: 1450px) {
    display: none;
  }

  @media (max-width: 1200px) {
    display: flex;
    right: 40px;
  }

  @media (max-width: 650px) {
    display: none;
  }
}
