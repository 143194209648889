.pill {
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  color: $white;
  background: $black;
  padding: 8px 12px;
  line-height: 24px;
  margin-right: 5px;
  margin-bottom: 5px;
  user-select: none;
}

.pill--no-margin {
  margin: 0;
}

.pill--desktop-only {
  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}

.pill--mobile-small {
  @media (max-width: $mobileBreakpoint) {
    padding: 5px 9px;
    font-size: 12px;
    line-height: 16px;
    font-weight: 300 !important;
  }
}

.pill--mobile-visible {
  display: inline-block !important;
  width: fit-content;
}

a.pill--tag,
.pill--tag {
  color: #777;
  background: $grayLightest;
  text-decoration: none;

  &.pill--white {
    background: $white;
    color: #777;
  }
}

.pill--link {
  cursor: pointer;
}

@media (min-width: 701px) {
  .pill--tag.pill--link:hover,
  a.pill--tag:hover {
    background: $black;
    color: $white;
  }
}

@media (max-width: 700px) {
  .pill--tag.pill--link:active,
  a.pill--tag:active {
    background: $black;
    color: $white;
  }
}

.pill--orange {
  color: $white;
  background: $primary;
}

.pill--yellow {
  background: $yellow;
  color: $black;
}

.pill--custom-color {
  color: $black;
}

.pill--custom-color-dark {
  color: $white;
}

.pill--green {
  background: $greenPrimary;
  color: $black;
}

.pill--dark-green-outline {
  color: $greenPrimary;
  background-color: $greenDark;
}

.pill--white {
  background: $white;
  color: $black;
}

.pill--gray {
  background: $grayLightest;
  color: $black;
}

.pill--gray-dark {
  background: $grayLight;
  color: $white;
}

.pill--job-length {
  float: none !important;
  margin-right: 16px;
}

.pill--dark-hoverable {
  color: $white;
  background: $black;
  cursor: pointer;

  &:hover {
    color: #777;
    background: $grayLightest;
  }
}

.pill--href {
  background: $grayDark;
  text-decoration: none;

  &:hover {
    background: $primary;
    cursor: pointer;
  }
}

.pill--href-green {
  background: $grayDark;
  text-decoration: none;

  &:hover {
    background: $greenPrimary;
    color: $black;
    cursor: pointer;
  }
}

.pill--visible {
  display: inline-block !important;
}

.pill--filter {
  background: $white;
  color: $grayLight;

  &.pill--filter-inactive {
    background: $grayLightest;
    color: #777;
  }

  &:hover {
    @media (min-width: 701px) {
      background: $black;
      color: $white;
      cursor: pointer;
    }
  }

  &:active {
    @media (max-width: 700px) {
      background: $black;
      color: $white;
      cursor: pointer;
    }
  }

  &:not(.pill--content-after-emoji) {
    &::before {
      content: attr(data-filter-name);

      @media (max-width: 700px) {
        content: attr(data-filter-short-name);
      }
    }
  }

  &.pill--content-after-emoji::after {
    content: attr(data-filter-name);

    @media (max-width: 700px) {
      content: attr(data-filter-short-name);
    }
  }
}

.pill--filter-active {
  background: $black;
  color: $white;
  cursor: pointer;

  &:hover {
    @media (min-width: 701px) {
      text-decoration: line-through;
    }
  }
}

.pill--small {
  padding: 5px 9px;
  font-size: 12px;
  line-height: 16px;
  font-weight: 300;
}

.pill--uppercase {
  text-transform: uppercase;
}

.pill--font-bold {
  font-weight: 500;
}

.pill--super-small {
  padding: 1px 5px;
  font-size: 11px;
  line-height: 15px;
  margin: 0;
  font-weight: 500;
}

.pill--super-small-blank {
  display: inline-block;
  height: 10px;
  width: 40px;
}

.pills-wrapper {
  margin-top: 10px;

  .pill.pill--more-mobile {
    display: none;
  }

  .pill.pill--more-desktop {
    display: inline-block;
  }

  @media (max-width: 600px) {
    .pill.pill--more-mobile {
      display: inline-block;
    }

    .pill.pill--more-desktop {
      display: none;
    }
  }

  @media (max-width: $smallMobileBreakpoint) {
    display: none;
  }
}

.pill--emoji {
  width: 40px;
}

.pill--margin-right {
  margin-right: 1px;
}

.pill.pill--margin-left-medium {
  margin-left: 6px;
}

.pill.pill--margin-left-small {
  margin-left: 1px;
}

.pill.pill--margin-left-medium {
  margin-left: 5px;
}

.pill.pill--margin-left-large {
  margin-left: 12px;
}

.pill--no-line-through {
  text-decoration: none !important;
}

.pill--new {
  &.pill--filter-active:hover {
    text-decoration: none;

    .pill--text {
      text-decoration: line-through !important;
    }
  }

  .pill {
    position: relative;
    top: -1px;
    margin: 0;
  }
}

.pill--position {
  position: relative;
  top: -2px;
}

.pill--closable {
  position: relative;
  padding-right: 32px;

  &:hover::after {
    color: $primary;
  }

  &::after {
    content: "×";
    position: absolute;
    top: 9px;
    right: 12px;
    cursor: pointer;
    width: 10px;
    height: 10px;
    font-size: 20px;
  }
}

.pill--dropdown {
  @media (max-width: 1170px) {
    padding-right: 45px;
  }

  padding-right: 32px;

  &:hover::after {
    background: url("/images/icons/icon-filter.svg") no-repeat;
    opacity: 1;
  }

  &::after {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: 12px;
    content: "";
    background: url("/images/icons/icon-filter-black.svg") no-repeat;
    background-size: contain;
    font-size: 10px;
    width: 10px;
    height: 10px;
    opacity: 0.4;
  }
}

.pill--dropdown-active,
.pill--dropdown-open {
  &::after {
    background: url("/images/icons/icon-filter.svg") no-repeat;
    opacity: 1;
  }
}

.pill--dropdown-open {
  &::after {
    transform: rotate(180deg);
    margin-top: -8px;
  }
}

.input-wrapper--selectbox .paragraph .pill.pill--super-small {
  top: -2px;
}
