.info-box {
  background: $grayLightest;
  text-align: center;
  padding: 40px 30px;
  user-select: none;

  @media (max-width: 880px) {
    padding-top: 50px;
  }

  a {
    color: $black;
    font-weight: 500;
  }

  p.paragraph--white a {
    color: $grayLightest;
  }
}

.info-box--yellow {
  background: $yellow;
}

.info-box--yellow-soft {
  background: $yellowSoft;
}

.info-box--gray {
  background: $grayDark;
  color: $white;
}

.info-box .container {
  @media (max-width: $touchBreakpoint) {
    padding: 0;
  }
}

.info-box-small {
  padding: 12px 20px;
  font-size: 16px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.info-box__text {
  display: inline-block;
  position: relative;
  line-height: 1.4;
  max-width: 900px;
  margin: 0 auto;
  padding: 0 65px;

  @media (max-width: 600px) {
    padding: 0;
  }
}

.info-box__icon {
  position: absolute;
  top: 50%;
  font-size: 48px;
  left: 0;
  margin-top: -33px;

  @media (max-width: 600px) {
    display: none;
  }
}

.info-box__icon--error {
  top: 15px;
}
