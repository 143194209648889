.button-secondary {
  display: inline-block;
  background: $grayLightest;
  color: $black;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  line-height: 26px;
  font-weight: 500;
  padding: 10px 15px;
  cursor: pointer !important;
  user-select: none;
  text-decoration: none;
  border: none;
  text-align: center;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  &.button--loading {
    cursor: not-allowed !important;
  }

  &.button-secondary--big {
    padding: 15px 40px;
  }

  &:hover:not(.button--loading) {
    background: $black;
    color: $white;
  }

  &.button-secondary--orange {
    background: $primary;
    color: $black;

    &:hover:not(.button--loading) {
      background: $black;
      color: $white;
    }
  }

  &.button-secondary--orange-white {
    background: $primary;
    color: $black;

    &:hover:not(.button--loading) {
      background: $white;
      color: $black;
    }
  }

  &.button-secondary--disabled {
    background: #979797;
    color: $white;
    pointer-events: none;
  }

  &.button-secondary--green {
    background: $greenPrimary;
    color: $black;
  }

  &.button-secondary--black {
    background: $black;
    color: $white;
    cursor: pointer;

    &:hover:not(.button--loading) {
      background: $primary;
      color: $black;
    }
  }

  &.button-secondary--black-white {
    background: $black;
    color: $white;
    cursor: pointer;

    &:hover:not(.button--loading) {
      background: white;
      color: $black;
    }
  }

  &.button-secondary--black-green {
    background: $black;
    color: $greenPrimary;
    cursor: pointer;

    &:hover:not(.button--loading) {
      background: $white;
      color: $black;
    }
  }

  &.button-secondary--white {
    background: $white;
    color: $black;
    cursor: pointer;

    &:hover:not(.button--loading) {
      background: $grayLightest;
      color: $black;
    }
  }

  &.button-secondary--pink {
    background: $white;
    color: $black;
    cursor: pointer;

    &:hover:not(.button--loading) {
      background: $grayLightest;
      color: $black;
    }
  }

  &.button-secondary--black-dark {
    background: $black;
    color: $primary;
    cursor: pointer;

    &:hover:not(.button--loading) {
      background: $white;
    }
  }

  .button__content-loader {
    position: absolute !important;
    top: 0 !important;
    left: 50% !important;
    margin-left: -10px !important;
    width: auto !important;
    text-align: center;
  }
}

.button-secondary--loading,
.button-secondary--loading:disabled {
  .button__content {
    position: relative;
  }

  .button__content--percentage {
    position: absolute;
    top: 0;
    left: 0;
    content: " ";
    width: 0%;
    max-width: 100%;
    height: 100%;
    background: #d23414;
    transition: all 80ms linear;
  }

  .button__content-text {
    opacity: 0;
  }

  .button__content-loader-wrapper {
    position: absolute;
    left: 0;
    top: 13px;
    display: inline-block;
    width: 100%;
    text-align: center;

    @media (max-width: $mobileBreakpoint) {
      top: 7px;
    }
  }

  .button__content--rotate {
    display: inline-block;
    animation: rotate 1s infinite linear;
  }

  .button__content-loader {
    position: absolute;
    top: 13px;
    left: 0;
    width: 100%;
    text-align: center;

    @media (max-width: $mobileBreakpoint) {
      top: 7px;
    }
  }
}
