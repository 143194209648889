.features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    grid-gap: 30px;
  }
}

.features__item {
  .emoji-component {
    font-size: 30px;
  }

  .features__item-headline {
    display: flex;
    align-items: center;
    gap: 10px;

    @media (max-width: $mobileBreakpoint) {
      flex-direction: column;
      align-items: flex-start;
      gap: 0;
    }
  }
}
