.newsletter {
  background: $primary;
  width: 100%;
  height: auto;
  padding: 60px;
  text-align: center;

  .heading-1 {
    max-width: 750px;
    line-height: 1.1;
    margin: 0 auto;
    margin-bottom: 24px;
  }

  @media (max-width: 700px) {
    padding: 60px 30px;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 40px 24px;

    .input__button {
      padding: 10px 0;
    }
  }
}

.newsletter__text {
  max-width: 750px;
  margin: 0 auto;
}

.newsletter__input {
  max-width: 750px;
  margin: 0 auto;
  margin-top: 20px;
}

.newsletter__checkboxes {
  display: flex;
  max-width: 750px;
  margin: 0 auto;
  margin-top: 55px;
  justify-content: space-between;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 25px;
  }

  @media (max-width: 800px) {
    display: block;
    overflow: hidden;

    .input-wrapper {
      float: left;
      width: 50%;
      padding-right: 10px;
      margin-bottom: 15px;
    }
  }

  @media (max-width: 355px) {
    .input-wrapper {
      width: 100%;
    }
  }
}

.newsletter--error {
  .input--with-button {
    padding-right: 210px;
  }

  .input__error-text--newsletter {
    right: 100px;
  }

  @media (max-width: 800px) {
    .input--with-button {
      padding-right: 125px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    .input__error-text--newsletter {
      right: 20px;
    }

    .input--with-button {
      padding-right: 50px;
    }
  }
}

.newsletter-modal {
  .small-modal {
    max-width: 900px;
  }

  @media (max-width: 800px) {
    .newsletter {
      min-height: 100vh;
    }
  }
}
