.baner-side-super-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding-bottom: 620px;
}

.banner-side-wrapper {
  position: sticky;
  width: 100%;
  top: 0;
  height: 0;
  padding-bottom: 600px;
}

.banner-side {
  cursor: pointer;
  position: sticky;
  top: 20px;
  width: 300px;
  height: 600px;
  margin-top: 20px;

  @media (max-width: 1820px) {
    display: none;
  }
}
