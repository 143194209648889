.bonuses {
  background: $white;
  padding: 0;

  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  &::after {
    position: relative;
    top: -1px;
    content: "";
    width: 100%;
    height: 1px;
    background: $white;
  }
}

.bonus {
  display: flex;
  overflow: hidden;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 28px 14px;
  flex: 0 0 33%;
  border-bottom: 1px dotted $black;
  border-right: 1px dotted $black;
  box-sizing: border-box;
  text-decoration: none;
  color: $black;

  &:nth-child(3n) {
    border-right: none;
  }

  &:hover {
    cursor: pointer;
    background: $grayLightRgba;
  }

  @media (max-width: 1100px) {
    flex: 0 0 50%;

    &:nth-child(3n) {
      border-right: 1px dotted $black;
    }

    &:nth-child(2n) {
      border-right: none;
    }
  }

  @media (max-width: 750px) {
    flex: 0 0 100%;

    &:nth-child(3n) {
      border-right: 1px dotted $black;
    }

    &:nth-child(2n) {
      border-right: 1px dotted $black;
    }

    &:nth-child(1n) {
      border-right: none;
    }
  }

  .button:hover {
    cursor: pointer !important;
  }
}

.bonus__headline {
  margin-top: 28px;
}

.bonus__paragraph {
  margin-bottom: 30px;
}

.bonus__image-wrapper {
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
}
