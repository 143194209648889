$footerBreakpoint: 800px;

.footer {
  background: $black;
  padding: 130px 0;
  color: $white;

  @media (max-width: $footerBreakpoint) {
    padding: 30px;
  }
}

.footer__container {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media (max-width: $footerBreakpoint) {
    flex-direction: column;
  }
}

.footer__link {
  display: inline-block;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  cursor: pointer;
  color: $white;
  margin-right: 30px;

  @media (max-width: $footerBreakpoint) {
    display: block;
    margin-right: 0;
    line-height: 36px;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.6;
  }

  &.footer__link--no-underline:hover {
    text-decoration: none;

    .footer__link-text {
      text-decoration: underline;
    }
  }

  .pill {
    position: relative;
    top: -2px;
  }
}

.footer__link-no-margin,
.footer__link:last-child {
  margin-right: 0;
}

.footer__link:hover {
  text-decoration: underline;
}

.footer__link-badge {
  position: relative;
  top: -2px;
  margin-left: 10px;
  display: inline-block;
  padding: 1px 4px;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  font-size: 11px;
  font-weight: 500;
  color: $white;
  border-radius: 2px;
  background-color: $primary;

  @media (max-width: $footerBreakpoint) {
    display: none;
  }
}

.footer__box {
  flex-grow: 1;

  @media (max-width: $footerBreakpoint) {
    text-align: center;
    width: 100%;
  }
}

.footer__box:nth-child(3) {
  @media (max-width: $footerBreakpoint) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

.footer__logo {
  display: block;
  background: url("/images/logo/logo_1.svg");
  background-size: cover;
  background-repeat: no-repeat;
  width: 250px;
  height: 65px;
  padding-top: 22px;
  padding-left: 65px;
  margin-left: 30px;

  @media (max-width: $footerBreakpoint) {
    margin: 0 auto !important;
    padding-left: 0;
  }

  @media (max-width: $footerBreakpoint) {
    width: 185px;
    height: 45px;
    padding-top: 10px;
  }
}

.footer__link--background-green {
  margin: 0 auto;
  background: url("/images/logo/logo-green.svg");
  background-size: cover;
  background-repeat: no-repeat;
  margin-left: 40px;
}

.footer__to-top-icon {
  position: relative;
  top: 4px;
}

.footer__contact {
  position: relative;
  z-index: 1;
  background: $grayDark;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  padding: 16px 0;
  text-align: center;
  user-select: none;
  text-decoration: none;

  .container {
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: 1000px) {
    height: auto;

    .container {
      flex-direction: column;
      gap: 8px;
    }
  }

  @media (max-width: $footerBreakpoint) {
    padding: 30px;

    .container {
      gap: 16px;
    }

    .footer__contact-left {
      display: flex;
      flex-direction: column;

      & > span {
        display: flex;
        flex-direction: column;
      }

      a {
        margin: 0 !important;
        line-height: 36px;
      }
    }
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;

    .footer__contact-left a {
      line-height: 1.6;
    }
  }
}
