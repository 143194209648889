.switcher-dark {
  border: 1px solid $white;
  display: inline-block;
  margin: 0 auto;
  margin-bottom: 20px;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
  }
}

.switcher-dark__button {
  position: relative;
  display: inline-block;
  width: 120px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding: 12px 0;
  color: $white;
  text-decoration: none;
  cursor: pointer;
  text-align: center;

  &.active {
    background: $white;
    color: $black;
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100% !important;
    font-size: 15px;
  }

  // &:not(.active) {
  //   text-decoration: underline;

  //   &:hover {
  //     text-decoration: none;
  //   }
  // }
}

.switcher-dark__pill {
  position: absolute;
  top: 5px;
  right: -50px;
  text-transform: uppercase;
  font-weight: 500;

  @media (max-width: 500px) {
    right: 5px;
  }
}
