$freelancersSimilarMobile: 880px;

.freelancers__similar {
  background: $grayLightest;
  padding: 60px 0;

  @media (max-width: $freelancersSimilarMobile) {
    padding: 20px 0;
    padding-bottom: 0;
  }
}

.freelancers__similar--white {
  background: $white;
}

.freelancers__similar--margin-bottom {
  margin-bottom: 60px;
}

.freelancers__similar--no-padding-top {
  padding-top: 0;
}

.freelancers__similar-button-wrapper {
  padding-top: 60px;

  @media (max-width: $freelancersSimilarMobile) {
    padding: 30px 0;
  }

  @media (max-width: 500px) {
    padding: 30px 20px;
  }
}

.freelancer__container-header {
  @media (max-width: 800px) {
    padding: 0 20px;
  }

  .heading-3 {
    cursor: pointer;
  }

  a:hover {
    text-decoration: none;

    span {
      text-decoration: underline;
    }
  }
}

.freelancer__radio-wrapper {
  display: flex;

  @media (max-width: 600px) {
    display: block;
  }
}

.freelancer__radio {
  height: 60px;
  width: 50%;
  padding: 10px 16px;
  padding-top: 11px;
  background: $grayLightest;
  border: 4px solid transparent;

  &:nth-child(1) {
    margin-right: 15px;
  }

  &:nth-child(2) {
    margin-left: 15px;
  }

  @media (max-width: 600px) {
    margin-top: 16px;
    width: 100%;

    &:nth-child(1) {
      margin-right: 0;
    }

    &:nth-child(2) {
      margin-left: 0;
    }
  }
}

.freelancer__radio--error {
  border: 4px solid $primary;
}

.freelancers__profile-upsell,
.freelancers__offer-modal {
  .offer-type-select-wrapper--margin-top {
    margin-top: 40px;
  }

  .offer-type-select__inner {
    min-height: 290px;
  }

  .offer-type-select:not(.offer-type-select--disabled) {
    border: 1px dashed $black;

    .offer-type-select__bg {
      cursor: pointer;
    }
  }

  .offer-type-select:not(.offer-type-select--disabled):hover {
    border: 1px dashed $black;

    .offer-type-select__bg {
      cursor: pointer;
      background: $graySuperLight;
      color: $black;
    }

    .offer-type-select__subheading--small {
      color: $black;
    }

    .offer-type-select__button.offer-type-select__button--green {
      background: $black !important;
      color: $graySuperLight !important;
    }
  }

  .offer-type-select--disabled {
    border: 1px dashed $black;
  }
}

.freelancers__upsell-button-wrapper {
  position: absolute;
  padding: 20px;
  padding-top: 40px;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.freelancers__upsell-button {
  position: sticky;
  top: 20px;
  z-index: 10;
}

.freelancers__profile-upsell {
  max-width: 810px;
}

.freelancers__login-upsell {
  position: relative;
}

.freelancers__login-badge {
  .freelancers__login-badge-price {
    font-size: 32px;
  }

  color: $greenPrimary;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: $black;
  text-transform: uppercase;
  transform: rotate(30deg);
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  top: 190px;
  left: 325px;
  width: 115px;
  height: 115px;

  @media (min-width: 1201px) and (max-width: 1370px) {
    display: none;
  }

  @media (max-width: 1200px) {
    left: 405px;
  }

  @media (max-width: 800px) {
    left: 332px;
  }

  @media (max-width: 450px) {
    display: none;
  }
}

.freelancers__testimonial {
  border: 1px dashed #777;
  color: $white;
  padding: 28px 22px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 16px;

  &.freelancers__testimonial--small {
    padding: 22px;
  }

  .pill {
    position: relative;
    top: -2px;
  }

  a {
    color: $white;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.freelancers__testimonial-flex {
  display: flex;
  gap: 22px;
}

.freelancers__testimonial-photo {
  width: 80px;
  height: 80px;
  margin-bottom: 8px;
}

.freelancers__testimonial-text {
  font-size: 18px;
  font-weight: 300;
  color: $white;

  &.freelancers__testimonial-text--small {
    font-size: 16px;
  }
}

.freelancers__pricing-wraper {
  display: flex;
  gap: 28px;

  > div {
    flex: 1;
  }

  @media (max-width: 1000px) {
    flex-direction: column;
  }
}

.freelancers__pricing-select {
  display: flex;
  flex-direction: column;
}

.freelancers__success-newsletter {
  background: $grayLightest;
  margin: -50px -60px;
  margin-top: 20px;
  padding: 50px 60px;

  .newsletter__checkboxes {
    margin-top: 0;
  }

  @media (max-width: 800px) {
    margin: -15px;
    margin-top: 15px;
    padding: 15px;
  }
}

.success-modal--freelancers {
  .small-modal {
    max-width: 850px;
  }
}

.freelancer__favourite-desktop {
  @media (max-width: 880px) {
    display: none;
  }
}

.freelancer__favourite-mobile {
  display: none;

  @media (max-width: 880px) {
    display: inline-flex;
    position: relative;
    top: 2px;

    .button-icon {
      flex: 0 0 17px;
      width: 17px;
      height: 17px;

      svg {
        width: 13px;
        height: auto;
      }
    }
  }
}

.freelancer__detail-tags-mobile {
  display: none;

  @media (max-width: $mobileBreakpoint) {
    display: inline;
  }
}

.freelancer__detail-tags-desktop {
  display: inline;

  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}

.freelancers__pro-microsite-header-dark-text {
  color: #777;
}

.freelancers__pro-microsite-header-slim-text {
  font-weight: 300;
}

.freelancers__pro-microsite-header-element-width {
  max-width: 362px;
}

.freelancers__pro-microsite-header-price-info {
  font-size: 30px;
  font-weight: 300;

  small {
    font-size: 22px;
  }
}

.freelancers__pro-microsite-header {
  padding-bottom: 90px;
}

.freelancers__pro-microsite-header-features-wrapper {
  padding-top: 180px;
}

.freelancers__pro-microsite-carousel-wrapper {
  position: absolute;
  bottom: -180px;
  width: 100%;
}

.freelancers__carousel {
  overflow: hidden;
  width: 100%;
  position: relative;

  &.freelancers__carousel--static {
    .freelancers__carousel-inner {
      animation: none;
    }
  }

  .freelancers__carousel-inner {
    display: flex;
    animation: scroll 300s linear infinite;

    .freelancers__carousel-inner-item {
      position: relative;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      width: 265px;
      height: 265px;
      background-color: #ddd;
      flex-shrink: 0;

      .freelancers__carousel-inner-item-overlay {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: rgba(0, 0, 0, 0.3);
      }

      .freelancers__carousel-inner-item-info-wrapper {
        position: absolute;
        bottom: 20px;
        left: 20px;
        right: 20px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .freelancers__carousel-inner-item-info-label {
          color: white !important;
          font-size: 14px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 100%;
          display: block;
          line-height: 18px;
        }
      }
    }
  }
}

@keyframes scroll {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(calc(-265px * 6 - 30px * 6));
  }
}

.pro-freelancer-offer-modal {
  .small-modal {
    max-width: 870px;
    background: #e9e9e9;
  }
}

.pro-freelancers-homepage-banner {
  background: $black;
  padding: 15px 15px 75px !important;
  overflow: hidden;

  .pro-freelancers-homepage-banner-inner {
    .banner-inner-content {
      display: flex;
      flex-direction: column;
      justify-content: center;

      @media (max-width: $mobileBreakpoint) {
        flex-direction: column;
        align-items: inherit;
      }

      h1 {
        color: $greenPrimary;
      }
    }

    .pro-freelancers-homepage-banner-carousel {
      @media (max-width: $mobileBreakpoint) {
        display: none;
      }
    }
  }
}

.freelancers-upsell-wrapper {
  @media (min-width: $mobileBreakpoint) {
    padding-right: 0 !important;
  }
}

.freelancers-login-banner {
  border: 12px $black solid;

  .freelancers-login-banner-title {
    line-height: 1.1;
    text-transform: uppercase;
  }

  .freelancers-banner-title-pill {
    top: -12px;
    margin: 0 !important;
  }

  .freelancers-login-banner-price-circle {
    position: absolute;
    right: 40px;
    bottom: 40px;
    background: $black;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    transform: rotate(20deg);

    span {
      margin: 0;
    }
  }

  .line-through {
    margin-bottom: 8px !important;
  }

  .heading-3 {
    line-height: 1;
  }

  .heading-4 {
    text-transform: lowercase;
    line-height: 1;
  }

  .small-text {
    font-size: 10px;
    text-align: center;
    color: $greenPrimary;
  }

  @media (max-width: $mobileBreakpoint) {
    .freelancers-banner-title-pill {
      top: -4px;
      padding: 6px;
    }

    .freelancers-login-banner-price-circle {
      display: none;
    }
  }

  @media (max-width: 600px) {
    .freelancers-login-banner-price-circle {
      display: none;
    }
  }

  .sale-circle {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    top: -60px;
    left: 50%;
    margin-left: -40px;
    background: white;
    color: $black;
    width: 80px;
    height: 80px;
    border-radius: 50%;
  }
}
