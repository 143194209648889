.profile__job-empty {
  border: 1px dashed $black;
  width: 100%;
  padding: 90px;
}

.profile__job-content {
  flex: 1;
  display: flex;
  align-items: center;

  @media (max-width: 1095px) {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}

.profile__job-left {
  width: 245px;
  padding-right: 15px;
}

.profile__job-left--small {
  width: 200px;
}

.profile__job-left--wide {
  width: 330px;
}

.profile__job-right {
  text-align: right;

  @media (max-width: 1095px) {
    width: 100%;
    text-align: left;
  }

  @media (max-width: 700px) {
    .button-secondary {
      width: 100%;
      text-align: center;
      margin-top: 15px;
    }
  }
}

.profile__job-right-multiple > * {
  margin-left: 15px;

  @media (max-width: 700px) {
    .button-secondary {
      margin-left: 0;
    }
  }
}

.profile__job-right-multiple *:first-child {
  margin-left: 0;
}

.profile__login-bar {
  padding-top: 100px;
  padding-bottom: 100px;

  .paragraph {
    margin: 0;
  }
}

.profile__signup-button {
  margin-top: 18px;
}

.profile__job-left--1 {
  @media (max-width: 700px) {
    width: calc(100% - 100px);
    margin-bottom: 0 !important;
    padding-right: 0;
  }
}

.profile__job-left--2 {
  @media (max-width: 700px) {
    margin-top: 20px;
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
  }
}

.profile__job-left--3 {
  @media (max-width: 900px) {
    margin-top: 20px;
  }

  @media (min-width: 700px) and (max-width: 900px) {
    width: 100%;
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    margin-top: 0;
  }
}

.profile__promo-2 {
  width: 215px;
}

.profile__promo-3 {
  width: 350px;
}

.profile__back-button {
  position: relative;
  color: $black;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  margin-left: 15px;

  &::before {
    content: "";
    width: 10px;
    height: 10px;
    background: url("/images/icons/icon-filter-black.svg") no-repeat;
    background-size: contain;
    position: absolute;
    transform: rotate(90deg);
    top: 8px;
    left: -20px;
    font-size: 10px;
  }

  &:hover {
    text-decoration: none;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.profile__job-heading {
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-weight: 900;
  color: $black;
  margin: 50px 0;
  word-break: break-word;

  @media (max-width: 700px) {
    font-size: 24px;
    margin: 30px 0;
  }
}

.profile__job-heading--loading {
  &::before {
    content: " ";
    display: inline-block;
    width: 300px;
    height: 40px;
    background: $black;

    @media (max-width: 700px) {
      width: 200px;
    }
  }
}

.profile__grid {
  display: flex;
  align-items: center;

  @media (max-width: 700px) {
    flex-wrap: wrap;
  }

  .profile__grid-item {
    @media (max-width: 700px) {
      margin-bottom: 15px;
      flex: 0 0 100%;
      width: 100%;
    }
  }
}

.profile__grid-flex-start {
  align-items: flex-start;
}

.profile__grid--head {
  background: $black;
  color: $white;
  padding: 7px 20px;

  @media (max-width: 1080px) {
    display: none;
  }
}

.profile__grid-item {
  flex: 0 0 25%;
  width: 25%;
  padding-right: 20px;

  &:last-child {
    padding-right: 0;
  }
}

.profile__grid-item--super-short {
  flex: 0 0 130px;
}

.profile__grid-item--short {
  flex: 0 0 160px;
}

.profile__grid-item--tall {
  flex: 1;
}

.profile__grid-item--cta {
  flex: 0 0 140px;
}

.profile__grid-item--tall,
.profile__grid-item--short,
.profile__grid-item {
  @media (max-width: 700px) {
    flex: 0 0 100%;
    width: 100%;

    .tooltip__button {
      display: none;
    }
  }
}

.profile__grid-item.text-right {
  @media (max-width: 700px) {
    text-align: left;
  }
}

.profile__grid-head-text {
  color: #f9f9f9;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  font-weight: 500;
  text-transform: uppercase;
  user-select: none;

  @media (max-width: 700px) {
    &.text-right {
      text-align: left;
    }
  }
}

.profile__grid-head-text--clickable {
  cursor: pointer;
}

.profile__candidate {
  padding: 20px;
  border-bottom: 1px dashed $black;

  @media (max-width: 700px) {
    padding: 15px;
    padding-bottom: 0;
  }
}

.profile__candidate-message {
  padding: 15px 0;

  &:first-of-type {
    padding-top: 0;
  }

  &:last-of-type {
    padding-bottom: 0;
  }

  & + .profile__candidate-message {
    border-top: 1px dashed $black;
  }
}

.profile__candidate-about {
  display: none;
  margin-top: 20px;

  p,
  li {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 0;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
      line-height: 1.6;
    }
  }

  ul {
    margin-top: 0;
  }

  @media (max-width: 700px) {
    margin-bottom: 15px;
  }

  a {
    color: $primary;

    &:hover {
      text-decoration: none;
    }
  }
}

.profile__candidate-link {
  color: $black;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 500;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
    cursor: pointer;
  }
}

.profile__candidate-name {
  flex: 0 0 25%;
  position: relative;
  overflow: hidden;

  @media (max-width: 1200px) {
    flex: 0 0 20%;
  }

  @media (max-width: 1110px) {
    flex: 0 0 18%;
  }

  @media (max-width: 920px) {
    overflow: visible;
  }

  @media (max-width: 700px) {
    padding-left: 0;
  }

  .pill {
    position: relative;
    top: -1px;
    margin-right: 4px;
  }
}

.profile__candidate-show-more.show-more {
  position: absolute;
  top: 11px;
  left: -25px;

  @media (max-width: 700px) {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 10px;
  }
}

.profile__candidate--open {
  .profile__candidate-about {
    display: block;
  }
}

.profile--availability-switcher {
  margin-top: 20px;
  margin-left: -24px;
  margin-right: -24px;

  @media (max-width: $mobileBreakpoint) {
    margin-left: 0;
    margin-right: 0;
  }

  .switcher {
    .switcher__button {
      width: 158px;
    }
  }
}

.profile--yellow-box {
  background: $yellow;
  padding: 30px;
  margin-left: -24px;
  margin-right: -24px;

  @media (max-width: $mobileBreakpoint) {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
  }
}

.profile--gray-box {
  background: $grayLightest;
  padding: 30px;
  margin-left: -24px;
  margin-right: -24px;
  margin-top: 20px;

  @media (max-width: $mobileBreakpoint) {
    margin-left: 0;
    margin-right: 0;
    padding: 20px;
  }

  &.profile--gray-box--favourite {
    padding: 12px 30px;

    @media (max-width: $mobileBreakpoint) {
      padding: 12px 20px;
    }

    &.profile--gray-box--favourite-active {
      background: $black;
      color: $white;

      a {
        color: white;
      }

      svg {
        color: $greenPrimary;
      }
    }
  }
}

.profile--small-padding {
  padding: 10px 30px;
}

.profile__job-left {
  .profile__pill-cv {
    position: relative;
    top: -1px;
    margin-left: 8px;
  }
}

.profile__detail-controls {
  z-index: 10;
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  background: $white;
  padding: 10px 0;

  .dropdown__item {
    display: inline-block;
    margin-left: 16px;
  }

  @media (max-width: 750px) {
    flex-direction: column;
    position: relative;

    .profile__back-button {
      margin-bottom: 16px;
    }
  }

  @media (max-width: 700px) {
    display: none;
  }
}

.profile__status-link {
  color: $black;
  text-decoration: none;
  cursor: pointer;
  font-weight: 500;

  @media (max-width: 500px) {
    display: block;
  }

  .profile__status-link-text {
    text-decoration: underline;
  }

  &:hover .profile__status-link-text {
    text-decoration: none;
  }

  &.profile__status-link--ok::before,
  &.profile__status-link--ko::before {
    margin-right: 5px;
    margin-left: 5px;
    font-size: 14px;
  }

  &.profile__status-link--note,
  &.profile__status-link--note-ok,
  &.profile__status-link--ok {
    margin-right: 10px;
  }

  &.profile__status-link--note,
  &.profile__status-link--note-ok,
  &.profile__status-link--ok,
  &.profile__status-link--ko {
    &::before {
      display: inline-block;
      position: relative;
      top: 3px;
      width: 16px;
      margin-right: 5px;
    }
  }

  &:first-child::before {
    margin-left: 0 !important;
  }

  &.profile__status-link--note-ok::before {
    content: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f4c4.svg");
  }

  &.profile__status-link--note::before {
    content: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/270f.svg");
  }

  &.profile__status-link--ok::before {
    content: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/2705.svg");
  }

  &.profile__status-link--ko::before {
    content: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/274c.svg");
  }
}

.profile__candidate--status-ok {
  background: rgba(105, 204, 39, 0.2);
}

.profile__candidate--status-ko {
  background-color: rgba(255, 67, 29, 0.1);
}

.profile__status-link.dropdown__handler {
  padding-right: 20px;
  margin-right: 0 !important;

  &::after {
    right: 0;
  }
}

.profile__candidate-gdpr {
  padding: 14px 20px;
  border-bottom: 1px dashed $black;
  background: $grayLightest;

  .paragraph {
    margin-top: 15px;
    margin-bottom: 15px;
  }

  @media (max-width: 700px) {
    padding: 15px;
  }
}

.profile__grid .profile__grid-item.profile__grid-item-mobile-half {
  @media (max-width: 700px) {
    flex: 0 0 50%;
    width: 50%;
  }
}

.profile__grid .stats__heading {
  @media (max-width: 700px) {
    font-size: 30px;
  }
}

.profile--wrapper-margin {
  margin: 50px auto;

  @media (max-width: 700px) {
    margin: 30px auto;
  }
}

.profile {
  @media (max-width: 700px) {
    .note-box__headline {
      display: none;
    }

    .note-box {
      br {
        display: none;
      }

      margin-top: -30px;
      margin-bottom: 30px;
    }
  }
}

.profile__candidate-upsell {
  position: relative;
  background: $greenPrimary;
  padding: 9px 15px;
  padding-left: 45px;
  padding-bottom: 11px;
  font-size: 16px;
  border-bottom: 1px dashed $black;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  a {
    color: $black;

    &:hover {
      text-decoration: none;
    }
  }
}

.profile__candidate-upsell-emoji {
  position: absolute;
  top: 12px;
  left: 15px;
  font-size: 18px;
  margin-right: 15px;

  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}

.profile__canditates-export {
  text-align: right;
  margin: 0;
  font-size: 16px;
  margin-bottom: 16px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  .tooltip__paragraph {
    text-decoration: none;
    color: #aaa;
    font-weight: 500;
    cursor: not-allowed;
  }

  a {
    text-decoration: none;
    color: $black;
    cursor: pointer;

    span {
      text-decoration: underline;
    }

    &:hover {
      span {
        text-decoration: none;
      }
    }
  }

  &.disabled a {
    color: #aaa;

    &:hover {
      span {
        text-decoration: underline;
      }
    }
  }
}

.profile__grid {
  .filter__icon--up {
    transform: rotate(180deg);
  }

  .filter__icon-double {
    position: relative;
    display: inline-block;
    width: 10px;
    height: 10px;

    .filter__icon {
      position: absolute;
      top: 5px;
      left: 5px;
    }

    .filter__icon--up {
      top: -2px;
      left: 4px;
      transform: rotate(180deg);
    }
  }
}

.profile__job-content .pill--super-small {
  margin-right: 4px;
}

.profile__candidate {
  @media (max-width: 1080px) {
    .profile__grid {
      flex-wrap: wrap;
    }

    .profile__grid-item--cta {
      flex: 0 0 100%;
      text-align: left;
      margin-top: 10px;
    }
  }

  @media (max-width: 920px) {
    .profile__candidate-name {
      flex: 1;
    }

    .profile__grid-item--tall {
      flex: 0 0 70%;
      text-align: left;
      margin-top: 10px;
    }

    .profile__grid-item--cta {
      flex: 0 0 30%;
      text-align: right;
      margin-top: 10px;
    }
  }

  @media (max-width: 700px) {
    .profile__grid-item--tall,
    .profile__grid-item--cta {
      flex: 0 0 100%;
      text-align: left;
      margin-top: 0;
    }

    .profile__grid-item--cta .button-secondary {
      width: 100%;
    }
  }
}

.profile--subscription {
  .job__info {
    font-size: 14px;
  }

  .pill {
    position: relative;
    top: -2px;
  }
}
