.button-full {
  position: relative;
  z-index: 1;
  display: block;
  background: $grayDark;
  width: 100%;
  height: 60px;
  font-size: 16px;
  font-weight: 500;
  color: $white;
  padding: 16px 0;
  text-align: center;
  user-select: none;
  text-decoration: none;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    height: 50px;
    line-height: 50px;
    padding: 0;
  }
}

.button--orange {
  background: $primary;

  a {
    color: $white;
  }
}

.button-hover--black {
  &:hover:not(.no-hover) {
    background: $black !important;
  }
}

.button-full.padding-horizontal {
  padding-left: 15px;
  padding-right: 15px;
}

.button-full:not(.no-hover):hover {
  background: $primary;
  cursor: pointer;

  @media (max-width: 700px) {
    background: $grayDark;
  }
}
