.submenu {
  position: sticky;
  top: 0;
  z-index: 5;
  display: block;
  background: $black;
  min-height: 80px;
  width: 100%;

  @media (max-width: 999px) {
    .container {
      white-space: nowrap;
      padding: 0 30px;
      -webkit-overflow-scrolling: touch;
      overflow-y: scroll;
      height: 100%;
    }
  }
}

.submenu__item {
  position: relative;
  user-select: none;
  color: $white;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  margin-right: 30px;
  line-height: 80px;
  cursor: pointer;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  &:hover {
    text-decoration: none;
  }
}

.submenu__item-pill {
  position: relative;
  top: -2px;
}

.submenu__item--active {
  text-decoration: none;
}

.submenu__item--with-badge {
  margin-right: 40px;
}

.submenu__item--right {
  margin-left: 15px;

  @media (min-width: 1000px) {
    float: right;
    margin-right: 0;
  }
}

.submenu__badge {
  background-color: $primary;
  padding: 0 5px;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  position: absolute;
  height: 16px;
  line-height: 16px;
  top: -10px;
  margin-left: -8px;
}

.submenu__badge--green {
  background-color: $greenPrimary;
  color: $black;
}
