$withoutImageBreakpoint: 1170px;

.talentbox {
  width: 100%;
  padding-top: 80px;
  padding-bottom: 60px;
  overflow: hidden;

  @media (max-width: $withoutImageBreakpoint) {
    padding: 90px 0;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 60px 0;
  }

  &.talentbox--no-padding-top {
    padding-top: 0;
  }
}

.talentbox--magazine {
  padding-top: 0;

  .talentbox__inner {
    background: $black;
    color: $white;
  }

  .talentbox__heading,
  .offer-type-select__subheading--small,
  .talentbox__subheading {
    color: $white;
  }

  .talentbox__button:hover {
    background: $white;
    color: $primary !important;
  }
}

.talentbox--freelancers {
  padding-top: 0;
  overflow: visible;

  .talentbox__inner {
    background: $greenPrimary;
    color: $black;
  }

  .offer-type-select__badge {
    background: $black;
    color: $white;
  }

  .talentbox__heading,
  .offer-type-select__subheading--small,
  .talentbox__subheading {
    color: $black;
  }

  .talentbox__button {
    background: $black;
    color: $greenPrimary !important;
  }

  .talentbox__button:hover {
    background: $white;
    color: $black !important;
  }
}

.talentbox__inner {
  position: relative;
  display: flex;
  background: $grayLightest;
  padding: 60px;

  @media (max-width: $withoutImageBreakpoint) {
    flex-direction: column;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 20px;
  }
}

.talentbox__left {
  max-width: 360px;

  @media (max-width: $withoutImageBreakpoint) {
    max-width: 100%;
  }
}

.talentbox__right {
  margin-left: 130px;

  @media (max-width: $withoutImageBreakpoint) {
    margin-left: 0;
    margin-top: 30px;
  }

  @media (max-width: $withoutImageBreakpoint) {
    display: none;
  }
}

.talentbox__paragraph {
  max-width: 500px;
}

.talentbox__heading {
  text-transform: uppercase;
  margin-top: 5px;
  margin-bottom: 30px;
}

.talentbox__heading-login {
  text-transform: uppercase;
  margin-top: 0;
  line-height: 1.2;

  @media (max-width: 400px) {
    font-size: 30px;
  }
}

.talentbox__button {
  max-width: 360px;
  margin-top: 30px;

  &:hover {
    background: $black;
    color: $white !important;
  }
}

.talentbox__list {
  padding: 0;
  margin: 30px 0;
  list-style: none;

  .talentbox__list-emoji {
    margin-right: 6px;
    font-size: 20px;
  }

  li {
    font-size: 16px;
    line-height: 27px;
    font-weight: 500;
    margin-bottom: 5px;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
      line-height: 24px;
    }

    a {
      color: $black;
    }
  }
}

.talentbox__list--freelancers {
  min-height: 155px;
}

.talentbox__subheading {
  font-weight: 300;
  font-size: 24px;
  line-height: 24px;

  .addon {
    font-size: 18px;
    line-height: 30px;
  }
}

.talentbox__img {
  position: absolute;
  top: 146px;
  left: 459px;
  width: 1091px;
  height: 421px;

  @media (max-width: $withoutImageBreakpoint) {
    display: none;
  }
}

.talentbox__ads-img {
  position: absolute;
  top: 50%;
  right: -45px;
  margin-top: -233px;
  width: 637px;
  height: 467px;

  @media (max-width: $withoutImageBreakpoint) {
    display: none;
  }
}

.talentbox__img-article {
  position: absolute;
  top: 80px;
  right: -100px;
  width: 678px;
  height: 500px;

  @media (max-width: 1400px) {
    right: 0;
  }

  @media (max-width: $withoutImageBreakpoint) {
    display: none;
  }
}

.talentbox__img-article-freelancers {
  position: absolute;
  top: -60px;
  right: -100px;
  width: 759px;
  height: 687px;

  @media (max-width: 1400px) {
    right: 0;
  }

  @media (max-width: $withoutImageBreakpoint) {
    display: none;
  }
}

.talentbox__stats {
  margin-top: 361px;

  @media (max-width: $withoutImageBreakpoint) {
    margin-top: 0;
  }
}

@keyframes text-slide2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-700px);
  }
}

.talentbox__carousel {
  position: absolute;
  top: -19px;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: hidden;

  @media (max-width: $withoutImageBreakpoint) {
    display: none;
  }
}

.talentbox__carousel-inner {
  position: absolute;
  background: $black;
  width: 2060px;
  animation: text-slide2 50s linear infinite alternate;
}

.talentbox__carousel-text {
  position: relative;
  top: -3px;
  display: inline-block;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  margin: 0 10px;
}

.talentbox__login-banner {
  position: relative;
  background: $primary url("/images/jobs/talent-box-with-badge-v2@2x.png") no-repeat;
  background-size: 765px;
  background-position: left 30px bottom 30px;
  padding-top: 30px;

  @media (max-width: 1650px) {
    background: $primary url("/images/jobs/talent-box-with-badge-v2@2x.png")  no-repeat;
    background-position: left 30px bottom 30px;
    background-size: calc(100% + 180px);
  }

  @media (max-height: 1300px) {
    background-position: left 30px bottom -60px;
  }

  @media (max-width: 1450px) {
    background-size: calc(100% + 150px);
  }

  @media (max-width: 1300px) {
    background-size: calc(100% + 130px);
  }

  @media (max-width: 1200px) {
    display: none;
  }
}

.talentbox__badge {
  position: absolute;
  top: 300px;
  left: 300px;
  transform: rotate(-330deg);
  color: $primary;
  font-family: "Poppins", sans-serif;
  font-size: 28px;
  line-height: 1.1;
  text-transform: uppercase;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  background: $black;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  flex-direction: column;

  span {
    font-size: 15px;
  }

  @media (max-width: 1220px) {
    display: none;
  }
}

.talentbox__info-link {
  font-size: 16px;
  margin-left: 30px;

  @media (max-width: 1300px) {
    margin-left: 16px;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.talentbox__info-link-cennik {
  display: inline-block;
  position: absolute;
  font-size: 16px;
  font-weight: 500;
  top: 18px;
  left: 390px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  @media (max-width: 750px) {
    position: relative;
    top: initial;
    left: initial;
    margin-top: 30px;
    margin-left: 120px;
  }

  @media (max-width: 540px) {
    margin: 0 auto;
    margin-top: 30px;
    display: block;
    text-align: center;
  }
}
