.social {
  position: sticky;
  top: 0;
  max-width: 500px;
  border: 1px solid $grayLightest;
  margin-bottom: 30px;
  text-align: left;
}

.social__text-preview {
  padding: 16px;
}

.social__summary {
  background: #f6f6f6;
  padding: 16px;
  border-top: 1px solid $grayLightest;

  .paragraph {
    margin: 0;
    font-size: 16px;
    line-height: 1.4;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }

    strong {
      font-size: 18px;
    }

    span {
      font-size: 12px;
    }
  }
}

.social__image-wrapper {
  width: 100%;
  min-height: 265px;
}

.social__preview {
  width: 500px;

  &.social__preview--full {
    width: 100%;
  }
}

.social__settings {
  width: 500px;
  padding-bottom: 30px;

  .textarea {
    min-height: 100px;
  }
}

.social__variant-wrapper {
  width: 300px;
}

.social__flex-container {
  display: flex;
  justify-content: space-between;
  grid-gap: 30px;
  margin-top: 30px;
  margin-bottom: 30px;
  padding-bottom: 30px;
  border-bottom: 1px dotted $black;

  .input-wrapper {
    margin-top: 0;
  }
}

.share__url-update-wrapper {
  position: relative;

  .input {
    padding-right: 150px;
  }

  .button-secondary {
    position: absolute;
    top: 7px;
    right: 7px;
  }
}
