.input-wrapper {
  position: relative;
  text-align: left;

  @media (max-width: $mobileBreakpoint) {
    &.margin-top-small {
      margin-top: 16px;
    }
  }

  &.input-wrapper--without-margin {
    margin: 0 !important;
  }
}

.input-salary-wrapper {
  display: flex;
  justify-content: space-between;

  @media (max-width: $mobileBreakpoint) {
    flex-direction: column;

    .input-wrapper:last-child .input.input--salary {
      border-top: 0;
    }
  }
}

.input-wrapper-divider {
  text-align: center;
  line-height: 60px;
  font-size: 18px;
  font-weight: 500;
}

.input-salary-wrapper .input-wrapper,
.input-salary-wrapper .input-wrapper-divider {
  display: inline-block;
  flex-grow: 1;
}

.label {
  display: block;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-size: 18px;
  margin-bottom: 10px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    margin-bottom: 5px;
  }

  .pill {
    position: relative;
    top: -2px;
    margin-left: 8px;
  }
}

label.paragraph {
  display: block;
}

.label--salary {
  background: $black;
  color: $white;
  width: 60px;
  height: 60px;
  position: absolute;
  top: 0;
  right: 0;
  line-height: 60px;
  text-align: center;

  @media (max-width: $mobileBreakpoint) {
    width: 50px;
    height: 50px;
    line-height: 50px;
  }
}

.input-wrapper--error {
  .label--salary {
    background: $primary;
  }

  .select-wrapper {
    border: 4px solid $primary;

    & + .input__error-text {
      right: 80px;
      bottom: 24px;

      @media (max-width: $mobileBreakpoint) {
        right: 70px;
        bottom: 20px;
      }
    }
  }
}

.input-salary-wrapper .input-wrapper {
  width: 45%;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    display: block;
  }
}

.input__button {
  position: absolute;
  right: 22px;
  top: 17px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: $black;
  text-decoration: underline;
  background: none;
  outline: none;
  border: none;
  cursor: pointer;

  @media (max-width: $mobileBreakpoint) {
    position: relative;
    right: initial;
    bottom: initial;
    display: block;
    text-align: center;
    width: 100%;
    padding: 20px 0;
    font-size: 15px;
  }
}

.input__button--hide {
  display: none;
}

.input__button:hover {
  text-decoration: none;
}

.input {
  border: 4px solid $black;
  padding: 18px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  color: $black;

  &::placeholder {
    color: $grayLight;
  }

  @media (max-width: $mobileBreakpoint) {
    height: 50px;
    padding: 0 14px;
    font-size: 14px;
  }
}

.input--salary {
  padding-right: 80px;

  @media (max-width: 330px) {
    padding-right: 70px;
  }
}

.input--length-counter {
  padding-right: 55px;
}

.input--with-button {
  padding-right: 105px;

  @media (max-width: $mobileBreakpoint) {
    padding-right: 24px;
  }
}

.input:not(:placeholder-shown):not(:focus) {
  background: $black;
  color: $white;
}

.input:not(:placeholder-shown):not(:focus) + .input__button {
  color: $white;
}

.input:not(:placeholder-shown):not(:focus):disabled,
.input:not(:placeholder-shown):read-only {
  background: $grayLight;
  border: $grayLight;
  color: $white !important;
}

.input.input--error {
  border: 4px solid $primary;
  padding-right: 140px;

  @media (max-width: 800px) {
    padding-right: 60px;
  }

  @media (max-width: 330px) {
    padding-right: 40px;
  }
}

.input__length-counter {
  position: absolute;
  right: 30px;
  bottom: 18px;
}

.input__length-counter--error {
  color: $primary;
}

.input__error-text {
  display: none;
  position: absolute;
  right: 30px;
  bottom: 18px;
  font-size: 16px;
  font-weight: 500;
  color: $primary;

  @media (max-width: 800px) {
    font-size: 0;
    bottom: 20px;

    &::before {
      content: " ";
      background-image: url("/images/icons/icon-error.svg");
      background-size: 20px 20px;
      display: block;
      width: 20px;
      height: 20px;
    }
  }

  @media (max-width: $mobileBreakpoint) {
    bottom: 15px;
    right: 14px;
    font-size: 15px;
  }
}

.input__error-text--newsletter {
  bottom: initial;
  top: 18px;

  @media (max-width: 800px) {
    top: 20px;
  }
}

.input__error-text--visible {
  display: block !important;
}

.form-section__title {
  font-size: 21px;
  font-weight: 500;
}

.select-wrapper {
  position: relative;
  cursor: pointer;
}

.select-arrow-wrapper {
  position: absolute;
  top: 4px;
  right: 4px;
  bottom: 4px;
  background: $white;
  content: "";
  pointer-events: none;
  aspect-ratio: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.select-arrow {
  border-style: solid;
  border-width: 5px 5px 0;
  border-color: $black transparent transparent transparent;
  pointer-events: none;
}

.select {
  border: 4px solid $black;
  padding: 15px 24px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  color: $white;
  background: $black;
  border-radius: 0;
  cursor: pointer;
  appearance: none;

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
  }

  &::-ms-expand {
    display: none;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 0 14px;
    height: 50px;
    font-size: 15px;
  }
}

.select-filter {
  position: relative;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
  display: inline-block;
  font-family: "Poppins", sans-serif;
  font-size: 15px;
  font-weight: 500;
  padding: 8px 12px;
  line-height: 24px;
  user-select: none;
  border: none;
  background: $grayLightest;
  color: #777;
  padding-right: 32px;

  &.select-filter--gray-with-black-text {
    color: $black;

    .select-filter__arrow {
      opacity: 1;
    }
  }

  &:hover {
    @media (min-width: 701px) {
      background: $black;
      color: $white;
      cursor: pointer;
    }

    .select-filter__arrow {
      background: url("/images/icons/icon-filter.svg") no-repeat;
      opacity: 1;
    }
  }

  &:-moz-focusring {
    color: transparent;
    text-shadow: 0 0 0 $black;
  }

  &::-ms-expand {
    display: none;
  }
}

.select-filter__select {
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  user-select: none;
  border: none;
  outline: none;
  border-radius: 0;
  cursor: pointer;
  appearance: none;
}

.select-filter__arrow {
  position: absolute;
  top: 50%;
  margin-top: -3px;
  right: 12px;
  content: "";
  background: url("/images/icons/icon-filter-black.svg") no-repeat;
  background-size: contain;
  font-size: 10px;
  width: 10px;
  height: 10px;
  opacity: 0.4;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
  user-select: none;

  & + label {
    cursor: pointer;
    padding: 0;
    font-size: 18px;
    line-height: 30px;
    user-select: none;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
      line-height: 1.6;

      span {
        position: relative;
        top: 2px;
      }
    }
  }

  & + label::before {
    content: "";
    position: relative;
    top: -2px;
    margin-right: 10px;
    display: inline-block;
    vertical-align: text-top;
    width: 30px;
    height: 30px;
    background: $white;
    border: 4px solid $black;
  }

  &:hover + label::before {
    background: $black;

    @media (max-width: $mobileBreakpoint) {
      background: $white;
    }
  }

  &:checked + label::before {
    background: $black;
  }

  &:disabled + label {
    color: #b8b8b8;
    cursor: auto;
  }

  &:disabled + label::before {
    box-shadow: none;
    background: #ddd;
    border-color: #ddd;
  }

  &:checked + label::after {
    content: "";
    position: absolute;
    left: 9px;
    top: 14px;
    background: white;
    width: 2px;
    height: 2px;
    box-shadow:
      1px 0 0 white,
      2px 0 0 white,
      3px 0 0 white,
      4px 0 0 white,
      5px 0 0 white,
      5px -1px 0 white,
      5px -2px 0 white,
      5px -3px 0 white,
      5px -4px 0 white,
      5px -5px 0 white,
      5px -6px 0 white,
      5px -7px 0 white,
      5px -8px 0 white,
      5px -9px 0 white,
      5px -10px 0 white,
      5px -11px 0 white;
    transform: rotate(45deg);
  }
}

.styled-checkbox--radio {
  z-index: 1;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.styled-checkbox--multiline {
  & + label {
    > span {
      display: inline-block;
      padding-left: 40px;
    }
  }

  & + label::before {
    position: absolute;
    top: 15px;
  }

  &:checked + label::after {
    top: 30px;
  }
}

.input-wrapper--checkbox {
  .pill {
    position: relative;
    top: -2px;
    padding-left: 5px;
    margin-left: 14px;
  }
}

.textarea-editor,
.textarea {
  border: 4px solid $black;
  padding: 18px 24px;
  box-sizing: border-box;
  width: 100%;
  min-height: 240px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  color: $black;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  @media (max-width: 330px) {
    padding: 14px;
  }

  a {
    color: $primary;
    font-weight: 500;
  }

  ol,
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-left: 15px;
  }

  ul li {
    margin-left: 0 !important;
    list-style: none;
    list-style-type: none !important;

    &::before {
      content: "👉 ";
    }

    div {
      display: inline !important;
      font-family: "Poppins", sans-serif;
      font-size: 16px;
      font-weight: 300;

      @media (max-width: $mobileBreakpoint) {
        font-size: 15px;
      }
    }
  }

  ol li {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }
  }
}

.textarea--modal {
  min-height: 220px;
}

.textarea--modal-small {
  min-height: 195px;
}

.textarea-editor.textarea--error,
.textarea.textarea--error {
  border: 4px solid $primary;
  padding-right: 140px;

  @media (max-width: 800px) {
    padding-right: 60px;
  }

  @media (max-width: 330px) {
    padding-right: 40px;
  }
}

.textarea:not(:placeholder-shown):not(:focus) {
  background: $black;
  color: $white;
}

.file-input__wrapper {
  position: relative;
  display: inline-block;
  margin-bottom: 20px;
  margin-right: 20px;
  cursor: pointer;
}

.file-input__input {
  position: absolute;
  top: 0;
  left: 0;

  // width: 126px;
  width: 100%;
  height: 46px;
  outline: none;
  opacity: 0;
  cursor: pointer !important;

  &:hover {
    cursor: pointer;

    & + .button-secondary.button-secondary--orange {
      background: $black;
      color: $white;
    }

    & + .button-secondary.button-secondary--black {
      background: $primary;
      color: $black;
    }
  }
}

.file-input__content {
  position: relative;
  display: inline-block;
  color: $white;
  background: $black;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  height: 46px;
  font-weight: 500;
  padding: 10px 25px;
  padding-right: 50px;
  margin-bottom: 20px;
  user-select: none;
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;

  .remove-button {
    position: absolute;
    top: 5px;
    right: 15px;
    font-size: 25px;
    transform: rotate(45deg);

    &:hover {
      cursor: pointer;
      color: $primary;
    }
  }
}

.file-input__content-normal-padding {
  padding: 15px 25px;
}

.input-wrapper--selectbox {
  padding: 15px;
  padding-bottom: 12px;
  border: 4px solid transparent;

  .styled-checkbox:checked + label::after {
    left: 24px;
    top: 30px;
  }

  .paragraph {
    margin: 0;
  }
}

.input-wrapper--selectbox-no-padding {
  padding: 0;
  border: 0;

  label.paragraph {
    padding-top: 16px;
  }

  .styled-checkbox:checked + label::after {
    left: 9px;
  }
}

.input-wrapper--selectbox.input-wrapper--error {
  padding-right: 150px;
  border: 4px solid $primary;

  // margin-bottom: 10px;

  .input__error-text {
    display: block;
  }

  .input__error-text-multiline {
    position: relative;
    top: initial;
    bottom: initial;
    left: initial;
    right: initial;
  }

  @media (max-width: 800px) {
    padding-right: 60px;
  }

  @media (max-width: $mobileBreakpoint) {
    .paragraph {
      font-size: 15px;
      line-height: 25px;
    }
  }

  @media (max-width: 330px) {
    padding-right: 40px;

    .input__error-text-multiline {
      right: 10px;
    }
  }
}

.photo-upload {
  position: relative;
  width: 100%;

  @media (max-width: 600px) {
    .flex {
      display: block;
    }
  }
}

.photo-upload.input-wrapper--error {
  border: 4px solid $primary;
  padding: 15px;
}

.photo-upload__img {
  float: left;
  width: 150px;
  height: 150px;
  margin-right: 35px;
  object-fit: cover;

  @media (max-width: 600px) {
    float: none;
  }
}

.photo-upload--freelancer {
  .photo-upload__img {
    border-radius: 50%;
  }
}

.error-message {
  font-size: 16px;
  font-weight: 500;
  color: $primary;
  border: 4px solid $primary;
  background-color: rgba(255, 67, 29, 0.12);
  width: 100%;
  padding: 15px 20px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.forgot-password {
  float: right;
  margin-top: 10px;
  font-weight: 500 !important;
}

.input--salary.input--error + .input__error-text {
  margin-right: 40px;
}

.input-wrapper--checkbox {
  a {
    color: $black;
  }
}

.input-wrapper--checkbox-multiline {
  label {
    position: relative;
    padding-left: 40px;

    &::before {
      position: absolute;
      top: 1px;
      left: 0;
    }
  }
}

.input-wrapper--checkbox-line-limit {
  label {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding-right: 5px;
  }
}

.input-wrapper--checkbox-bg {
  background: $graySuperLight;
  padding: 14px 20px;

  .paragraph {
    margin-bottom: 0;
  }
}

.input-wrapper--checkbox-upsell-yellow {
  background: $yellow url("/images/ocicka-small@2x.png") no-repeat top center;
  background-size: contain;
  padding: 26px 30px;

  .styled-checkbox:checked + label::after {
    top: 16px;
    left: 9px;
  }

  .paragraph {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    background: $yellow;
    padding: 14px 20px;
  }
}

.input-wrapper--checkbox-sng {
  border: none;
  background: url("/images/sng/1@2x.png") no-repeat $sngBg;
  background-size: cover;
  padding: 16px 30px;
  padding-right: 20px;
  margin-bottom: 30px;
  min-height: 102px;

  label {
    padding-left: 60px;
    color: white;
  }

  &.input-wrapper--checkbox-multiline label::before {
    top: 50% !important;
    margin-top: -15px !important;
  }

  &.input-wrapper--checkbox-multiline label::after {
    top: 50% !important;
    left: 8px !important;
    margin-top: -1px !important;
  }

  .paragraph {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    background: $sngBg;
    padding: 14px 20px;

    label {
      color: black;

      br {
        display: none !important;
      }
    }
  }
}

.input-wrapper--checkbox-sng-support {
  border: none;
  background: url("/images/sng/2@2x.png") no-repeat $sngSupportBg;
  background-size: cover;
  padding: 16px 30px;
  padding-right: 20px;
  margin-bottom: 30px;
  min-height: 102px;

  label {
    padding-left: 60px;
  }

  &.input-wrapper--checkbox-multiline label::before {
    top: 50% !important;
    margin-top: -15px !important;
  }

  &.input-wrapper--checkbox-multiline label::after {
    top: 50% !important;
    left: 8px !important;
    margin-top: -1px !important;
  }

  .paragraph {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    background: $sngSupportBg;
    padding: 14px 20px;

    label {
      br {
        display: none !important;
      }
    }
  }
}

.input-wrapper--checkbox-manifest {
  border: none;
  background: url("/images/manifest/form-checkbox@2x.png") no-repeat #fb3421;
  background-size: cover;
  padding: 16px 30px;
  padding-right: 20px;
  margin-bottom: 30px;
  min-height: 102px;

  label {
    padding-left: 60px;
  }

  &.input-wrapper--checkbox-multiline label::before {
    top: 50% !important;
    margin-top: -15px !important;
  }

  &.input-wrapper--checkbox-multiline label::after {
    top: 50% !important;
    left: 8px !important;
    margin-top: -1px !important;
  }

  .paragraph {
    margin-bottom: 0;
  }

  @media (max-width: 800px) {
    background: $yellow;
    padding: 14px 20px;
  }
}

.input__visibility-toggle {
  float: right;
  cursor: pointer;
  font-size: 16px;
  margin-top: 2px;
  user-select: none;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  svg {
    position: relative;
    top: 4px;
    margin-right: 8px;
    font-size: 1.2rem;
  }
}

.input-autocomplete {
  position: relative;
  text-align: left;
}

.autocomplete__control {
  border-radius: 0 !important;
  border: 4px solid $black !important;
  box-shadow: none !important;
  padding: 0;
  padding-left: 16px;
  box-sizing: border-box;
  width: 100%;
  height: 60px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  outline: none;
  color: $black;
  cursor: text !important;
  transition: none !important;

  @media (max-width: $mobileBreakpoint) {
    height: 50px;
    font-size: 14px;
    padding-left: 6px;
  }

  &::after {
    content: "";
    position: absolute;
    top: 0;
    width: 0;
    height: 0;
    right: 20px;
    bottom: 0;
    margin: auto;
    border-style: solid;
    border-width: 5px 5px 0;
    border-color: $black transparent transparent transparent;
    pointer-events: none;

    @media (max-width: $mobileBreakpoint) {
      right: 16px;
    }
  }

  &::before {
    width: 52px;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    background: $white;
    content: "";
    pointer-events: none;

    @media (max-width: $mobileBreakpoint) {
      width: 42px;
    }
  }
}

.autocomplete__indicator-separator {
  background-color: $black !important;
  width: 4px !important;
  margin: 0 !important;
}

.autocomplete__dropdown-indicator {
  color: $white !important;
  background: $black !important;
  cursor: pointer !important;
  padding: 16px !important;

  @media (max-width: $mobileBreakpoint) {
    padding: 11px !important;
  }
}

.autocomplete__menu {
  margin-top: 5px !important;
  border-radius: 0 !important;
  border: 4px solid $black !important;
  box-shadow: none !important;
  z-index: 10 !important;
}

.autocomplete__option {
  cursor: pointer !important;
  padding-left: 26px !important;
}

.autocomplete__single-value {
  color: $black !important;
}

.company-input .autocomplete__control::after,
.company-input .autocomplete__control::before {
  display: none;
}

.company-input .input__error-text.input__error-text--visible {
  right: 30px !important;
}

.input-wrapper--autocomplete.input-wrapper--error {
  .input__error-text {
    right: 85px;

    @media (max-width: $mobileBreakpoint) {
      right: 60px;
    }
  }

  .autocomplete__value-container {
    padding-right: 150px;

    @media (max-width: 800px) {
      padding-right: 60px;
    }

    @media (max-width: $mobileBreakpoint) {
      padding-right: 40px;
    }
  }

  .autocomplete__control {
    border: 4px solid $primary !important;
  }

  .autocomplete__indicator-separator {
    background-color: $primary !important;
  }

  .autocomplete__placeholder {
    width: calc(100% - 150px);

    @media (max-width: 800px) {
      width: calc(100% - 60px);
    }

    @media (max-width: $mobileBreakpoint) {
      width: calc(100% - 50px);
    }
  }
}

.autocomplete__input input {
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 500;
  color: $black;

  @media (max-width: $mobileBreakpoint) {
    font-size: 14px;
  }
}

.autocomplete-container--selected {
  .autocomplete__control {
    background: $black !important;
  }

  .autocomplete__single-value,
  .autocomplete__input input {
    color: $white !important;
  }

  .autocomplete__control--is-disabled {
    background: #777 !important;
    border: 4px solid #777 !important;

    &::after {
      border-color: #777 transparent transparent;
    }

    .autocomplete__indicator-separator {
      background-color: #777 !important;
    }
  }
}

.autocomplete__clear-indicator {
  cursor: pointer !important;
  color: $white !important;

  &::before {
    font-size: 22px;
    content: "+" !important;
    transform: rotate(45deg);
  }

  &:hover {
    &::before {
      color: $primary;
    }
  }

  svg {
    display: none;
    cursor: pointer !important;
  }
}

.autocomplete__multi-value {
  background: $white !important;
}

/* stylelint-disable-next-line */
.autocomplete__multi-value__label {
  font-family: "Poppins", sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: $black !important;

  @media (max-width: $mobileBreakpoint) {
    font-size: 14px !important;
  }
}

/* stylelint-disable-next-line */
.autocomplete__multi-value__remove {
  cursor: pointer !important;

  &::before {
    font-size: 22px;
    content: "+" !important;
    transform: rotate(45deg);
  }

  &:hover {
    &::before {
      color: $primary;
    }
  }

  svg {
    display: none;
    cursor: pointer !important;
  }
}

.input-wrapper--autocomplete-multiple .autocomplete-container--selected {
  .autocomplete__control {
    padding-left: 0;
  }

  .autocomplete__value-container {
    padding: 0;
  }

  .autocomplete__multi-value {
    padding: 10px 5px;
    padding-top: 11px;
    margin: 0;
    margin-right: 4px;

    @media (max-width: $mobileBreakpoint) {
      padding: 2px 5px;
      line-height: 33px;
      height: 42px;
    }
  }

  /* stylelint-disable-next-line */
  .autocomplete__multi-value__remove:hover {
    background: transparent !important;
  }
}

.autocomplete__value-container {
  overflow: hidden;
  height: 51px;

  @media (max-width: $mobileBreakpoint) {
    height: 43px;
  }
}

.autocomplete__placeholder {
  white-space: nowrap;
  overflow: hidden;
  display: block;
  text-overflow: ellipsis;
  width: 90%;
}

.colorpicker__handler {
  cursor: pointer;
}

.colorpicker {
  display: inline-block;
  position: relative;

  &:hover {
    .colorpicker__handler-wrapper {
      border: 4px solid $black;
    }
  }

  .twitter-picker {
    z-index: 10 !important;
    position: absolute !important;
    width: 280px !important;
    top: 40px;
    right: 24px;
    border-radius: 0 !important;
    box-shadow: none !important;
    border: 1px solid rgba(0, 0, 0, 0.1) !important;

    div,
    input {
      border-radius: 0 !important;
    }

    span {
      > div {
        border-radius: 0 !important;
      }

      > div:hover {
        outline: none !important;
        box-shadow: none !important;
        border: 4px solid $black !important;
      }
    }
  }
}

.colorpicker--freelancers {
  .twitter-picker {
    top: 40px;
    left: 14px;
    right: initial;
  }
}

.autocomplete__indicators {
  @media (max-width: $mobileBreakpoint) {
    height: 42px;
    min-height: 30px;
  }
}

.colorpicker__handler-wrapper {
  border: 4px solid $grayLightest;
  position: relative;
  width: 54px;
  height: 30px;
  margin-left: 10px;
  margin-right: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.colorpicker__handler {
  display: inline-block;
  width: 38px;
  height: 10px;

  &::before {
    content: "";
    position: absolute;
    top: -1px;
    margin-top: -3px;
    right: -25px;
    width: 22px;
    height: 20px;
  }

  &::after {
    position: absolute;
    top: 50%;
    margin-top: -3px;
    right: -25px;
    content: "";
    background: url("/images/icons/icon-filter-black.svg") no-repeat;
    background-size: contain;
    font-size: 10px;
    width: 10px;
    height: 10px;
  }
}

input[type="file"],
input[type="file"]::-webkit-file-upload-button {
  cursor: pointer;
}

.checkboxes--error {
  border: 4px solid $primary;
  padding: 10px;
}

.checkboxes-items {
  display: flex;
  flex-wrap: wrap;

  .input-wrapper {
    width: 50%;

    @media (max-width: 700px) {
      width: 100%;
    }

    .checkboxes--3cols & {
      width: 33%;

      @media (max-width: 700px) {
        width: 100%;
      }
    }
  }
}

select,
textarea,
input {
  font-size: 16px;
}

.input-range-wrapper {
  display: flex;
  gap: 16px;
}

.input-range {
  appearance: auto;
}

.styled-checkbox-pill {
  position: absolute;
  opacity: 0;
  user-select: none;

  & + label {
    display: inline-block;
    font-family: "Poppins", sans-serif;
    font-size: 15px;
    font-weight: 500;
    padding: 8px 12px;
    line-height: 24px;
    margin-right: 5px;
    margin-bottom: 5px;
    user-select: none;
    cursor: pointer;
    background: $grayLightest;
    color: #777;

    &:hover {
      @media (min-width: 701px) {
        background: $black;
        color: $white;
        cursor: pointer;
      }
    }
  }

  &:checked + label {
    background: $black;
    color: $white;

    &:hover {
      text-decoration: line-through;
    }
  }
}
