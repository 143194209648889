.icon {
  display: inline-block;
  cursor: pointer;

  &:hover svg {
    @media (min-width: 600px) {
      fill: $primary;
    }
  }

  &.green-hover {
    &:hover svg {
      @media (min-width: 600px) {
        fill: $greenPrimary;
      }
    }
  }
}

.icon__fb {
  margin-left: 13px;
}

.icon__tw,
.icon__fb,
.icon__mail {
  width: 30px;
  height: 30px;
  margin-right: 22px;
}

.icon__mail {
  svg {
    position: relative;
    top: 3px;
  }
}

.icon__link {
  width: 30px;
  height: 30px;
}

.back__icon {
  margin-right: 8px;
}
