.companies {
  background: $white;
  padding: 30px;

  @media (max-width: 700px) {
    padding-left: 0;
    padding-right: 0;
  }

  &.companies--gray {
    background: $grayLightest;
  }
}

.companies__text {
  font-family: "Poppins", sans-serif;
  font-size: 18px;
  font-weight: 300;
  line-height: 1.4;
  margin: 0;
  margin-bottom: 70px;
  text-align: center;

  @media (max-width: $mobileBreakpoint) {
    margin-bottom: 40px;
  }
}

.companies__logo-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  row-gap: 70px;
  margin-bottom: 30px;

  @media (max-width: 1040px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (max-width: $mobileBreakpoint) {
    grid-template-columns: 1fr;
  }
}

.companies__logo {
  display: flex;
  margin: 0 auto;

  &.square {
    width: 120px;
    height: 120px;

    @media (max-width: 600px) {
      width: 80px;
      height: 80px;
      margin-bottom: 20px;
    }
  }
}

.hot-companies-container {
  padding-top: 10px;
  padding-bottom: 10px;

  @media (max-width: $touchBreakpoint) {
    padding: 5px 20px;
  }
}

.hot-companies {
  display: flex;
  gap: 10px;
  align-items: stretch;
  flex-wrap: nowrap;
  overflow-x: auto;

  @media (max-width: $touchBreakpoint) {
    gap: 5px;
  }
}

.hot-company-item {
  box-sizing: border-box;
  background-color: $grayLightest;
  display: flex;
  align-items: center;
  color: $black;
  padding: 10px;
  text-decoration: none;
  flex: 1;

  .company__logo {
    display: flex;
    margin-right: 8px;
    min-width: 80px;
    min-height: 80px;
    max-height: 80px;
    max-width: 80px;
    width: 100%;
    height: 100%;

    @media (max-width: $touchBreakpoint) {
      min-width: 60px;
      min-height: 60px;
      max-height: 60px;
      max-width: 60px;
      margin-bottom: 8px;
      margin-right: 0;
    }
  }

  .hot-company-item__info-wrapper {
    display: flex;
    font-size: 15px;
    flex-direction: column;
    line-height: 1.4;

    @media (max-width: $touchBreakpoint) {
      flex-direction: column-reverse;
      align-items: center;
    }

    .hot-company-item__name {
      @media (max-width: $touchBreakpoint) {
        display: none;
      }
    }

    .hot-company-item__jobs {
      color: #777;
      font-weight: 300;
      white-space: nowrap;
    }
  }

  &:hover {
    background-color: $black;
    color: $white !important;

    .hot-company-item__jobs {
      color: $white;
    }
  }

  @media (max-width: $touchBreakpoint) {
    flex-direction: column;
    justify-content: center;
  }
}

.hot-company-item__loader {
  height: 100px;

  @media (max-width: $touchBreakpoint) {
    height: 130px;
  }
}
