html {
  height: 100%;
}

body {
  min-height: 100%;
}

html,
body {
  margin: 0;
  padding: 0;
  color: $black;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}

html.modal-visible,
body.modal-visible,
html.modal-visible > body {
  overflow: hidden;
  height: 100%;

  canvas {
    z-index: 103 !important;
  }
}

::selection {
  color: $black;
  background: $primary;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  appearance: none;
  margin: 0;
}

hr {
  border: none;
  border-bottom: 1px dotted $black;
}
