.faq__item--opened {
  .faq__item-question {
    text-decoration: none;
    border-bottom: 1px solid transparent;
  }
}

.faq__item-question {
  padding: 24px 0;
  border-bottom: 1px dotted $black;
  margin: 0;
  cursor: pointer;
  user-select: none;
  text-decoration: underline;
}

.faq__item-answer {
  padding-bottom: 24px;
  margin: 0;
  border-bottom: 1px dotted $black;
}
