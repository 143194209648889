$desktopBreakpoint: 1185px;
$tabletBreakpoint: 1000px;

.offer-type-select-outer-wrapper {
  height: auto;
  margin-top: 70px;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 40px;
  }
}

.offer-type-select-wrapper {
  user-select: none;
  position: relative;
  display: flex;
  justify-content: space-around;
  text-align: left;
  width: 100%;
  padding: 0;

  @media (max-width: $tabletBreakpoint) {
    flex-flow: column;
  }
}

.offer-type-select-wrapper--margin-top {
  margin-top: 20px;
}

.offer-type-select-next-content {
  padding-top: 520px;

  @media (max-width: 1185px) {
    padding-top: 650px;
  }

  @media (max-width: 800px) {
    padding-top: 580px;
  }

  @media (max-width: $tabletBreakpoint) {
    padding: 0;
  }
}

.offer-type-select {
  position: relative;
  justify-content: space-between;
  display: flex;
  flex-flow: column;
  width: 50%;
  color: $black;
  text-decoration: none;

  .flex {
    justify-content: space-between;
  }

  &:nth-child(1) {
    margin-right: 15px;
  }

  &:nth-child(2) {
    margin-left: 15px;
    margin-right: 15px;
  }

  &:nth-child(3) {
    margin-left: 15px;
  }

  .offer-type-select__bg {
    background: $white;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .offer-type-select__main {
    flex-grow: 1;
    background: #fff;
    cursor: pointer;

    &:hover {
      background: $black;
      color: $white !important;

      p,
      span,
      div {
        color: $white !important;
      }

      & ~ div {
        .offer-type-select__button {
          background: $black;
          color: $white !important;
        }
      }

      .tooltip__button {
        background: url("/images/icons/tooltip-white.svg");
      }
    }
  }

  @media (max-width: $tabletBreakpoint) {
    width: 100%;
    margin: 0 0 20px !important;
  }
}

.offer-type-select--disabled {
  cursor: not-allowed;

  p,
  li,
  span {
    color: #777 !important;
  }

  .tooltip__button {
    display: none;
  }

  .offer-type-select__button {
    background: #777 !important;
    color: $white !important;
    cursor: not-allowed;

    a {
      color: $white !important;

      &:hover {
        text-decoration: none;
      }
    }
  }
}

.offer-type-select--selected {
  .offer-type-select__button--select {
    display: none;
  }

  .offer-type-select__button--disabled {
    display: block;
  }
}

.offer-type-select__button--grey {
  background: $grayDark !important;
  color: $white !important;
}

.offer-type-select__button.offer-type-select__button--green {
  background: $greenPrimary !important;
  color: $black !important;

  &:hover {
    background: $black !important;
    color: $white !important;
  }
}

.offer-type-select__inner {
  display: flex;
  flex-direction: column;
  padding: 25px 20px;
  padding-top: 50px;
}

.offer-type-select__inner--small-padding-top {
  padding-top: 25px;
}

.offer-type-select__inner--bottom {
  padding: 25px 20px;
}

.offer-type-select__inner--space-between {
  justify-content: space-between;
}

.offer-type-select__heading {
  position: relative;
  font-family: "Rubik", sans-serif;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
}

.offer-type-select__badge-wrapper {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100%;
}

.offer-type-select__badge {
  color: $white;
  font-family: "Poppins", sans-serif;
  font-size: 12px;
  font-weight: 500;
  text-transform: uppercase;
  background-color: $primary;
  padding: 1px 5px;
  white-space: nowrap;
  margin-right: 5px;
}

.offer-type-select__badge--yellow {
  background-color: $yellow;
  color: $black;
}

.offer-type-select__badge--black {
  background-color: $black;
  color: $white;
}

.offer-type-select__subheading {
  font-size: 30px;
  font-weight: 300;
  line-height: 1;

  strong {
    font-weight: 500;
  }

  .tooltip__button {
    margin-left: 5px;
  }
}

.offer-type-select__subheading-disabled {
  color: $grayLight !important;
  text-decoration: line-through;
  text-decoration-color: $grayLight;

  img {
    filter: grayscale(100%);
  }
}

.offer-type-select__subheading--margin-top-small {
  margin-top: 12px;
}

.offer-type-select__subheading--margin-top {
  margin-top: 24px;
}

.offer-type-select__subheading--small {
  font-size: 16px;
  font-weight: 300;
  line-height: 24px;
  color: $black;
  margin-top: 5px;

  &.margin-top-small {
    margin-top: 10px;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  .pill {
    position: relative;
    top: -3px;
    margin-right: 10px;
  }

  .pill--super-small {
    top: -2px;
  }
}

.offer-type-select__subheading--line {
  text-decoration: line-through;
}

.offer-type-select__emoji {
  position: absolute;
  top: 10px;
  right: 30px;
  font-size: 48px;
}

.offer-type-select__button {
  display: block;
  width: 100%;
  font-size: 16px;
  font-weight: 500;
  text-align: center;
  background: $primary;
  cursor: pointer;
  padding: 18px 14px;
  user-select: none;
  text-decoration: none;
  color: $black !important;

  &:hover {
    background: $black;
    color: $white !important;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.offer-type-select__button--disabled {
  display: none;
  background: $black !important;
  color: $white !important;
  cursor: default;
}

.offer-type-select__button--disabled-visible {
  background: $black !important;
  color: $white !important;
  cursor: not-allowed;
}

.offer-type-select__ul {
  list-style: none;
  padding-left: 20px;
  margin-top: 15px;
  margin-bottom: 0;
}

.offer-type-select__ul--margin-top {
  margin-top: 15px !important;
}

.offer-type-select__li {
  margin: 0;
  line-height: 1.5;
  padding: 2px 0;
  font-size: 16px;
}

.offer-type-select__li::before {
  content: "-";
  margin-left: -20px;
  margin-right: 10px;
}

.offer-type-select__subheading--rocket,
.offer-type-select__subheading--emoji {
  padding-left: 30px;
}

.offer-type-select__subheading--icon {
  position: relative;
  top: 2px;
  margin-right: 6px;
  font-size: 20px;
}

.offer-type-select__subheading--emoji::before {
  content: "🤩";
  margin-left: -25px;
  font-size: 22px;
  position: relative;
  top: 5px;
  left: -7px;
}

.offer-type-select__subheading--rocket::before {
  content: "🚀";
  z-index: 1;
  margin-left: -25px;
  font-size: 22px;
  position: relative;
  top: 5px;
  left: -7px;
}

.offer-type-select__subheading--yellow {
  position: relative;

  strong {
    position: relative;
    z-index: 1;
  }
}

.offer-type-select__subheading--yellow::after {
  content: "";
  position: absolute;
  width: 100%;
  top: 9px;
  left: 0;
  height: 20px;
  background: $yellow;
  z-index: 0;
}

.offer-type-select__subheading--footer {
  text-align: center;

  // background: $white;
  margin-top: 0;
  padding-top: 10px;
  font-size: 14px;
  color: $black !important;
  min-height: 36px;
  cursor: pointer;
}

.offer-type-select__hr-text {
  @media (max-width: 1005px) {
    display: none;
  }
}

@keyframes text-slide2 {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-700px);
  }
}

.offer-type-select__carousel {
  position: absolute;
  top: -19px;
  left: 0;
  width: 100%;
  height: 20px;
  overflow: hidden;

  @media (max-width: 1000px) {
    display: none;
  }
}

.offer-type-select__carousel-inner {
  position: absolute;
  background: $black;
  width: 1260px;
  animation: text-slide2 50s linear infinite alternate;
}

.offer-type-select__carousel-text {
  position: relative;
  top: -3px;
  display: inline-block;
  text-align: center;
  font-family: "Poppins", sans-serif;
  font-size: 14px;
  letter-spacing: 0.5px;
  line-height: 19px;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  margin: 0 10px;
}

.offer-type-select__checkbox {
  label::before {
    position: absolute;
    top: -1px;
  }
}

.offer-type-select__checkbox-text {
  display: flex;
  font-size: 16px;
  font-weight: 500;
  margin-left: 40px;
  justify-content: space-between;
  align-items: center;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;

    span {
      top: 0 !important;
    }

    & > span {
      top: -2px !important;
    }
  }

  .pill {
    position: relative;
    top: -1px;
    line-height: 17px;
    margin-left: 0;
    height: 19px;

    @media (max-width: 1150px) {
      display: none;
    }
  }
}

.offer-type-select__checkbox-text-small {
  font-size: 14px;
}

.offer-type-select__checkbox-text-crossed {
  text-decoration: line-through;
  color: $primary;
  font-weight: 300;
}

.offer-type-select__notebox {
  background: $white;
  padding: 16px;
  margin-top: 60px;

  @media (max-width: $mobileBreakpoint) {
    margin-top: 10px;
  }
}

.offer-type-select--freelancer {
  width: 100%;
  margin: 0 !important;

  .tooltip__button {
    display: none;
  }

  .offer-type-select__heading {
    .pill {
      position: relative;
      top: -7px;
      margin-left: 8px;
    }
  }
}

.offer-type-select__subheading--line-height-small {
  line-height: 14px !important;
}

.freelancers-select {
  .offer-type-select {
    width: 100%;
  }
}

.offer-type-benefits {
  margin-bottom: 0;
  font-size: 16px;

  svg {
    position: relative;
    top: 5px;
  }
}

.offer-type-footer-info-wrapper {
  display: flex;
  align-items: center;
  margin-top: 16px;
  margin-left: auto;
  width: 361px;
  padding: 6px;
  justify-content: center;
  font-size: 14px;
  gap: 4px;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
    white-space: pre;
  }

  .offer-type-footer-info:not(.link) {
    font-weight: 300;
  }
}
