// mixinis
@mixin figcaption-default {
  margin: -45px 0 60px;
  text-align: center;
  color: $black;
  opacity: 0.5;
  font-weight: 300;

  @media (min-width: 1200px) {
    max-width: none;
    width: calc(100% + 390px);
    margin: 0 0 60px -195px;
  }

  @media (max-width: $mobileBreakpoint) {
    margin: -20px 0 20px;
  }
}

@mixin image-default {
  margin: 60px auto;
  width: 100%;

  @media (min-width: 1200px) {
    max-width: none;
    width: calc(100% + 390px);
    margin: 60px 0 15px -195px;
  }

  @media (max-width: $mobileBreakpoint) {
    margin: 30px auto;
  }
}

// styles

.magposts {
  background-color: $grayLightest;
  padding-top: 45px;
  padding-bottom: 80px;

  @media (max-width: $mobileBreakpoint) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.magposts-category {
  background-color: $grayLightest;
  padding-top: 80px;
  padding-bottom: 80px;

  @media (max-width: $mobileBreakpoint) {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}

.magposts__wrapper {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 30px;
  padding-top: 45px;
  padding-bottom: 45px;

  @media (max-width: 767px) {
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
  }

  @media (min-width: 768px) {
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: $mobileBreakpoint) {
    padding-top: 15px;
    padding-bottom: 15px;
  }

  &--related {
    .magpost__content {
      background-color: $white;
    }
  }
}

.magpost {
  cursor: pointer;
  text-decoration: none;
  box-shadow: 10px 10px 0 0 black;

  &:hover h3 {
    text-decoration: none;
  }
}

.magpost__image-wrapper {
  position: relative;
  height: 200px;
  overflow: hidden;

  > div {
    display: block !important;
    user-select: none;
  }

  img {
    object-fit: cover;
  }
}

.magpost__footer {
  position: absolute;
  left: 20px;
  bottom: 15px;
}

.magpost__content {
  height: 115px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
  background-color: $white;

  @media (max-width: 1150px) {
    height: 135px;
  }

  @media (max-width: 767px) {
    height: auto;
    min-height: 115px;
  }
}

.magpost__header {
  flex: 1 1 auto;
}

.magpost__title {
  color: $black;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: flex;
  overflow: hidden;
  white-space: normal;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.magposts__newsletter {
  margin: 60px 0 70px;
  grid-column: 1 / -1;
  grid-row-start: 3;
  grid-row-end: 3;

  > .newsletter {
    padding: 0;
    background: none;
  }
}

.bg--default {
  background-color: $white;
}

.bg--rozhovory {
  background-color: #9c9ddd;
}

.bg--inspiracie {
  background-color: #77eee0;
}

.bg--yablko-radi {
  background-color: #f4c7ed;
}

.bg--aktuality {
  background-color: #fedccc;
}

.bg--zo-zakulisia {
  background-color: #ff8fa4;
}

.bg--zakulisia {
  background-color: #ff9965;
}

.bg--nezaradene {
  background-color: $white;
}

.bg--podujatia {
  background-color: $black;
}

.mag-post-detail {
  padding-top: 80px;

  @media (max-width: $mobileBreakpoint) {
    padding-top: 35px;
  }
}

.mag-post-detail__header {
  text-align: center;

  .pill {
    @media (max-width: 767px) {
      padding: 5px 9px;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.mag-post-detail__heading {
  margin-top: 20px;
  margin-bottom: 30px;
  font-family: "Rubik", sans-serif;
  font-size: 54px;
  line-height: 1.3;
  font-weight: 900;
  color: $black;

  @media (max-width: $touchBreakpoint) {
    font-size: 30px;
    line-height: 1.2;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 24px;
  }
}

.mag-post-detail__meta {
  font-size: 16px;
  font-weight: 500;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.mag-post-detail__cover {
  @include image-default;
}

.mag-post-detail__cover-title {
  @include figcaption-default;

  margin-bottom: 60px;
}

.mag-post-detail__newsletter {
  margin-top: 60px;
  padding-bottom: 70px;

  > .newsletter {
    padding: 0;
    background: none;
  }
}

.mag-post-detail__content {
  strong {
    font-weight: 500;
  }

  h2,
  h3 {
    color: $black;
    font-weight: 500;
    margin-top: 60px;
    margin-bottom: 30px;

    @media (max-width: $mobileBreakpoint) {
      margin-top: 30px;
      margin-bottom: 15px;
    }
  }

  h2 {
    font-size: 30px;
    line-height: 1.5;

    @media (max-width: $mobileBreakpoint) {
      font-size: 24px;
    }
  }

  h3 {
    font-size: 24px;
    line-height: 1.5;

    @media (max-width: $mobileBreakpoint) {
      font-size: 18px;
    }
  }

  p {
    font-size: 18px;
    font-weight: 300;
    line-height: 1.7;
    margin-bottom: 15px;

    @media (max-width: $mobileBreakpoint) {
      font-size: 15px;
    }
  }

  hr {
    margin: 30px 0;

    @media (min-width: 1200px) {
      margin: 60px 0;
    }
  }

  .blocks-gallery-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr 1fr;
    list-style-type: none;
    margin: 60px 0;
    padding: 0;

    @media (max-width: $touchBreakpoint) {
      grid-template-columns: 1fr;
    }

    li {
      margin: 0;
    }

    img {
      max-width: 100% !important;
      width: auto !important;
      margin: 0 !important;
      display: block !important;
    }
  }

  .wp-block-gallery.columns-1 .blocks-gallery-grid {
    grid-template-columns: 1fr;
  }

  .wp-block-gallery.columns-2 .blocks-gallery-grid {
    grid-template-columns: 1fr 1fr;

    @media (max-width: $touchBreakpoint) {
      grid-template-columns: 1fr;
    }
  }

  .wp-block-gallery.columns-3 .blocks-gallery-grid {
    grid-template-columns: 1fr 1fr 1fr;

    @media (max-width: $touchBreakpoint) {
      grid-template-columns: 1fr;
    }
  }

  .wp-block-gallery.columns-4 .blocks-gallery-grid {
    grid-template-columns: 1fr 1fr 1fr 1fr;

    @media (max-width: $touchBreakpoint) {
      grid-template-columns: 1fr 1fr;
    }
  }

  .blocks-gallery-caption {
    @include figcaption-default;
  }

  blockquote,
  blockquote > p {
    max-width: 555px;
    display: block;
    color: $black;
    font-size: 30px;
    line-height: 1.4;
    font-weight: 500;
    text-align: center;
    margin: 80px auto;

    @media (max-width: $mobileBreakpoint) {
      font-size: 20px;
      margin: 50px auto;
    }
  }

  blockquote > p::before {
    content: "\201C";
  }

  blockquote > p::after {
    content: "\201D";
  }

  .wp-block-image:not(.fixed-size) img {
    display: block;

    @include image-default;
  }

  .wp-block-image.fixed-size img {
    display: block;
    margin: 60px auto;
  }

  figure img + figcaption {
    @include figcaption-default;
  }

  .wp-block-embed__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;

    > iframe {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }

  a {
    color: $black;
    font-weight: 500;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  .wp-block-table {
    white-space: nowrap;
    -webkit-overflow-scrolling: touch;
    overflow-x: auto;
    margin-left: -24px;
    margin-right: -24px;
    padding-left: 24px;
    padding-right: 24px;

    @media (min-width: 1200px) {
      max-width: none;
      width: calc(100% + 390px);
      margin: 60px 0 15px -195px;
      padding: 0;
    }

    figcaption {
      text-align: center;
      color: $black;
      opacity: 0.5;
      font-weight: 300;
      margin-top: 15px;
      margin-bottom: 60px;

      @media (min-width: 1200px) {
        width: 100%;
      }
    }
  }

  table {
    margin: 0;
    width: 100%;
    border-collapse: collapse;
  }

  td {
    white-space: pre-wrap;
    border: 1px solid $black;
    padding: 6px 10px;
    text-align: left;
    word-break: normal;
    min-width: 85px;
  }
}

.mag-latest-posts {
  background-color: $grayLightest;
  padding: 15px 0;

  @media (min-width: 1024px) {
    min-height: 345px;
  }
}

.mag-latest-posts__wrapper {
  padding-top: 45px;
  padding-bottom: 45px;

  @media (min-width: 1024px) {
    display: grid;
    grid-gap: 30px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1023px) {
    white-space: nowrap;
    padding: 30px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
    height: 100%;

    .magpost {
      display: inline-block;
      width: 340px;
      margin-right: 30px;
      margin-bottom: 15px;

      @media (max-width: 450px) {
        width: 285px;
      }
    }
  }
}

// share post
.share-post__wrapper {
  position: relative;
  margin: 60px 0;
  padding: 50px 0;
  border-top: 1px dotted $black;
  border-bottom: 1px dotted $black;

  @media (max-width: $mobileBreakpoint) {
    padding: 25px 0;
    margin: 30px 0;
  }

  .container {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));

    @media (max-width: 670px) {
      grid-template-columns: repeat(1, minmax(0, 1fr));
    }
  }
}

.magpost__title--loading {
  &::before {
    content: " ";
    display: inline-block;
    width: 70%;
    height: 20px;
    background: $black;

    @media (max-width: 700px) {
      width: 200px;
    }
  }
}

.magpost__title--loading-size-2 {
  &::before {
    width: 60%;
  }
}

.magpost__title--loading-size-3 {
  &::before {
    width: 40%;
  }
}

.twitter-tweet {
  margin: 0 auto !important;
}

.mag__category-filter {
  white-space: nowrap;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  height: 100%;
  min-height: 45px;

  .pill {
    display: inline-block;
  }
}
