$upsellMobileBreakpoint: 500px;

.upsell {
  background: $yellow url("/images/ocicka@2x.png") no-repeat;
  background-size: 1200px auto;
  padding: 60px 0;

  @media (max-width: 1180px) {
    background: $yellow;
  }

  @media (max-width: 600px) {
    .input-wrapper {
      width: 100%;
    }

    .input-wrapper:last-child {
      margin-top: 16px;
    }

    .input-salary-wrapper {
      flex-direction: column;
    }
  }
}

.upsell--yellow {
  background: $yellow !important;
}

.upsell--green {
  background: $greenPrimary !important;
}

.upsell--green-sng {
  background: url("/images/sng/2@2x.png") no-repeat $sngSupportBg !important;
  background-size: 800px !important;
  background-position: right 0 !important;

  @media (max-width: 800px) {
    background-image: none !important;
  }
}

.upsell--orange {
  background: $primary !important;
  color: $white !important;
}

.upsell--black-text {
  color: $black !important;
}

.upsell--black {
  background: $black !important;
  color: $white !important;
}

.upsell--hidden {
  display: none;
}

.upsell-standard {
  position: relative;
  display: flex;
  align-items: center;
  background: #db086d;
  padding: 26px;
  margin-top: 30px;
  margin-bottom: 16px;
  min-height: 60px;
}

.upsell-standard--smaller {
  padding: 12px 16px;
  padding-left: 26px;
  line-height: 1.3;
}

.upsell-standard--clickable {
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.upsell-standard__icon {
  position: absolute;
  top: 50%;
  left: 16px;
  margin-top: -23px;
  font-size: 30px;

  @media (max-width: $upsellMobileBreakpoint) {
    display: none;
  }
}

.upsell-standard__text {
  padding-left: 30px;
  font-size: 16px;
  line-height: 1.5;
  font-weight: 300;
  margin-bottom: 0 !important;

  @media (max-width: $upsellMobileBreakpoint) {
    padding-left: 0;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  strong {
    font-weight: 500;
  }
}

.upsell-standard__text--no-icon {
  padding-left: 0;
}

.upsell-small {
  position: relative;
  display: block;
  align-items: center;
  background: #db086d;
  padding: 12px 16px;
  margin-top: 30px;
  margin-bottom: 16px;
  text-decoration: none;

  &.upsel-small--gray {
    background: $grayLightest;
    color: $black;
  }

  &:hover.upsell-small--clickable {
    .link {
      text-decoration: none;
    }
  }
}

.upsell-small--block {
  display: block;
}

.upsel-small--no-margin-top {
  margin-top: 0;
}

.upsel-small--no-margin-bottom {
  margin-bottom: 0;
}

.upsel-small--with-icon {
  padding-left: 55px;

  @media (max-width: $upsellMobileBreakpoint) {
    padding-left: 16px;
  }
}

a.upsell-small {
  text-decoration: none;
  color: $white;

  &:hover {
    text-decoration: none !important;

    .underline {
      text-decoration: none !important;
    }
  }
}

.upsell-small--clickable {
  cursor: pointer;

  a {
    text-decoration: underline;
  }

  &:hover {
    a {
      text-decoration: none !important;
    }
  }
}

.upsell-small--without-margin {
  margin: 0;
}

.upsel-small__text {
  padding-left: 30px;
  font-size: 14px;
  line-height: 18px;
  font-weight: 300;
  margin-bottom: 0 !important;

  strong {
    font-weight: 500;
  }
}

.upsell-small__icon {
  position: absolute;
  top: 50%;
  left: -40px;
  margin-top: -23px;
  font-size: 30px;
  margin-right: 6px;

  @media (max-width: $upsellMobileBreakpoint) {
    display: none;
  }
}

.upsell-small--center {
  justify-content: center;
}

.upsell-line {
  display: inline-block;
  background: $yellow;
  color: $black;
  cursor: pointer;
  padding: 10px 16px;
  padding-top: 8px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }

  .pill {
    position: relative;
    top: -1px;
    margin-right: 6px;
  }
}
