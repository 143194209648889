.ltr {
  text-align: left;
}

.rtl {
  text-align: right;
}

.wysiwyg-next {
  border: 4px solid $black;
  width: 100%;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  outline: none;
  color: $black;
}

.wysiwyg-next-error {
  border-color: $primary;
}

.wysiwyg-next__inner {
  background: $white;
  position: relative;
}

.wysiwyg-next__input {
  min-height: 240px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  resize: none;
  caret-color: rgb(5, 5, 5);
  position: relative;
  tab-size: 1;
  outline: 0;
  padding: 18px 24px;
}

.wysiwyg-next__placeholder {
  color: $grayLight;
  overflow: hidden;
  position: absolute;
  text-overflow: ellipsis;
  top: 18px;
  left: 24px;
  font-size: 16px;
  font-weight: 500;
  user-select: none;
  display: inline-block;
  pointer-events: none;
}

.editor-text-bold {
  font-weight: 500;
}

.editor-text-italic {
  font-style: italic;
}

.editor-text-underline {
  text-decoration: underline;
}

.editor-text-strikethrough {
  text-decoration: line-through;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-text-underlineStrikethrough {
  text-decoration: underline line-through;
}

.editor-text-code {
  background-color: rgb(240, 242, 245);
  padding: 1px 0.25rem;

  // font-family: Menlo, Consolas, Monaco, monospace;
  font-size: 94%;
}

.editor-link {
  color: $primary;
  font-weight: 500;
  text-decoration: underline;
}

.tree-view-output {
  display: block;
  background: #222;
  color: #fff;
  padding: 5px;
  font-size: 12px;
  white-space: pre-wrap;
  margin: 1px auto 10px;
  max-height: 250px;
  position: relative;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  overflow: auto;
  line-height: 14px;
}

.editor-code {
  background-color: rgb(240, 242, 245);
  display: block;
  padding: 8px 8px 8px 52px;
  line-height: 1.53;
  font-size: 13px;
  margin: 0;
  margin-top: 8px;
  margin-bottom: 8px;
  tab-size: 2;

  /* white-space: pre; */
  overflow-x: auto;
  position: relative;
}

.editor-code::before {
  content: attr(data-gutter);
  position: absolute;
  background-color: #eee;
  left: 0;
  top: 0;
  border-right: 1px solid #ccc;
  padding: 8px;
  color: #777;
  white-space: pre-wrap;
  text-align: right;
  min-width: 25px;
}

.editor-code::after {
  content: attr(data-highlight-language);
  top: 0;
  right: 3px;
  padding: 3px;
  font-size: 10px;
  text-transform: uppercase;
  position: absolute;
  color: rgba(0, 0, 0, 0.5);
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenComment {
  color: slategray;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenPunctuation {
  color: #999;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenProperty {
  color: #905;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenSelector {
  color: #690;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenOperator {
  color: #9a6e3a;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenAttr {
  color: #07a;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenVariable {
  color: #e90;
}

/* stylelint-disable-next-line selector-class-pattern */
.editor-tokenFunction {
  color: #dd4a68;
}

.editor-paragraph {
  margin: 0;
  margin-bottom: 15px;
  position: relative;
}

.editor-paragraph:last-child {
  margin-bottom: 0;
}

.editor-heading-h1 {
  font-size: 24px;
  color: rgb(5, 5, 5);
  font-weight: 400;
  margin: 0;
  margin-bottom: 12px;
  padding: 0;
}

.editor-heading-h2 {
  font-size: 15px;
  color: rgb(101, 103, 107);
  font-weight: 700;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  text-transform: uppercase;
}

.editor-quote {
  margin: 0;
  margin-left: 20px;
  font-size: 15px;
  color: rgb(101, 103, 107);
  border-left: 4px solid rgb(206, 208, 212);
  padding-left: 16px;
}

.editor-list-ol {
  padding: 0;
  margin: 0;
  margin-left: 15px;
  margin-bottom: 48px;

  li.editor-listitem {
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin: 8px 32px;
    margin-left: 15px;
  }
}

.editor-list-ul {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-left: 15px;
  margin-bottom: 48px;

  li.editor-listitem {
    list-style: none;
    list-style-type: none !important;
    font-family: "Poppins", sans-serif;
    font-size: 16px;
    font-weight: 300;
    margin: 8px 32px;
    margin-left: 0;

    &::before {
      content: "👉 ";
    }
  }
}

.editor-nested-listitem {
  list-style-type: none;
}

pre::-webkit-scrollbar {
  background: transparent;
  width: 10px;
}

pre::-webkit-scrollbar-thumb {
  background: #999;
}

.debug-timetravel-panel {
  overflow: hidden;
  padding: 0 0 10px;
  margin: auto;
  display: flex;
}

.debug-timetravel-panel-slider {
  padding: 0;
  flex: 8;
}

.debug-timetravel-panel-button {
  padding: 0;
  border: 0;
  background: none;
  flex: 1;
  color: #fff;
  font-size: 12px;
}

.debug-timetravel-panel-button:hover {
  text-decoration: underline;
}

.debug-timetravel-button {
  border: 0;
  padding: 0;
  font-size: 12px;
  top: 10px;
  right: 15px;
  position: absolute;
  background: none;
  color: #fff;
}

.debug-timetravel-button:hover {
  text-decoration: underline;
}

.wysiwyg-next__toolbar {
  position: relative;
  border-bottom: 4px solid $black;
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
  background: #fff;
  padding: 4px;
  vertical-align: middle;
}

.wysiwyg-next__toolbar button.toolbar-item {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background: $white;
  border: 2px solid $black;
  height: 34px;
}

.wysiwyg-next__toolbar.orange {
  button.toolbar-item:hover:not(:disabled),
  button.toolbar-item.active {
    background: $primary;
  }
}

.wysiwyg-next__toolbar.green {
  button.toolbar-item:hover:not(:disabled),
  button.toolbar-item.active {
    background: $greenPrimary;
  }
}

.wysiwyg-next__toolbar button.toolbar-item:disabled {
  cursor: not-allowed;
}

.wysiwyg-next__toolbar .divider {
  width: 1px;
  border-left: 2px solid $black;
  margin: 0 4px;
}

.wysiwyg-next__toolbar select.toolbar-item {
  border: 0;
  display: flex;
  background: none;
  border-radius: 10px;
  padding: 8px;
  vertical-align: middle;
  appearance: none;
  width: 70px;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
}

.wysiwyg-next__toolbar select.code-language {
  text-transform: capitalize;
  width: 130px;
}

.wysiwyg-next__toolbar .toolbar-item .text {
  display: flex;
  line-height: 20px;
  vertical-align: middle;
  font-size: 14px;
  color: #777;
  text-overflow: ellipsis;
  width: 70px;
  overflow: hidden;
  height: 20px;
  text-align: left;
}

.wysiwyg-next__toolbar .toolbar-item .icon {
  display: flex;
  width: 20px;
  height: 20px;
  user-select: none;
  margin-right: 8px;
  line-height: 16px;
  background-size: contain;
}

.wysiwyg-next__button-wrapper {
  position: relative;

  @media (max-width: 700px) {
    position: static;
  }
}

.wysiwyg-next__emoji-picker {
  position: absolute;
  top: 38px;
  left: 0;
  z-index: 100;

  @media (max-width: 700px) {
    top: 100%;
    width: 100%;
  }
}

em-emoji-picker {
  --border-radius: 0;
  --shadow: none;
  --color-border: rgba(0, 0, 0, 1);

  border: 2px solid $black;
  width: 350px;

  @media (max-width: 700px) {
    width: 100%;
  }
}

.wysiwyg-next__toolbar.orange {
  em-emoji-picker {
    --rgb-accent: 255, 67, 29;
  }
}

.wysiwyg-next__toolbar.green {
  em-emoji-picker {
    --rgb-accent: 52, 255, 109;
  }
}
