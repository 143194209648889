.top-bar-2 {
  position: relative;
  z-index: 1;
  width: 100%;
  display: block;
  background: #3cb778;
}

.top-bar-2__sponsored {
  text-transform: uppercase;
  background: $graySuperLight;
  color: $black;
  font-size: 12px;
  transform: rotate(180deg);
  writing-mode: vertical-lr;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;

  @media (max-width: 1050px) {
    font-size: 10px;
  }

  @media (max-width: 750px) {
    font-size: 8px;
  }

  @media (max-width: 600px) {
    display: none;
  }
}

.top-bar-2-desktop-content {
  position: relative;
  height: auto;
  display: block;
  width: 100%;
  max-width: 1180px;
  margin: 0 auto;

  img {
    display: inherit;
    width: 100%;
    height: auto;
  }

  @media (max-width: 900px) {
    display: none;
  }
}

.top-bar-2-tablet-content {
  display: none;
  position: relative;
  height: auto;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  img {
    display: inherit;
    width: 100%;
    height: auto;
  }

  @media (min-width: 401px) and (max-width: 900px) {
    display: block;
  }
}

.top-bar-2-mobile-content {
  display: none;
  position: relative;
  height: auto;
  width: 100%;
  max-width: 1440px;
  margin: 0 auto;

  img {
    display: inherit;
    width: 100%;
    height: auto;
  }

  @media (max-width: 400px) {
    display: block;
  }
}
