.accordeon {
  margin: 0 -30px;

  @media (max-width: $mobileBreakpoint) {
    margin: 0 -24px;
  }
}

.accordeon--error {
  border: 4px solid $primary;
}

.accordeon--no-overflow {
  margin: 0;
}

.accordeon__title {
  color: $black;
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  display: block;
  min-height: 60px;
  padding: 19px 30px;
  background: $grayLightest;
  border-bottom: 1px solid $white;
  cursor: pointer;
  user-select: none;

  &.open {
    background: $white;
  }

  &.accordeon__title--not-clickable {
    cursor: default;
  }

  @media (max-width: $mobileBreakpoint) {
    padding: 14px 24px;
    font-size: 15px;
    line-height: 1.6;
    min-height: 50px;
  }

  .input-wrapper--checkbox {
    float: right;
    position: relative;
    top: -4px;

    label {
      margin: 0;
    }
  }
}

.accordeon__title-icon {
  position: relative;
  top: 2px;
  margin-right: 15px;
}

.accordeon__items {
  flex-wrap: wrap;
  gap: 5px;
  padding: 16px 30px;
  display: none;

  @media (max-width: $mobileBreakpoint) {
    padding: 16px 24px;
  }

  &.open {
    display: flex;
  }

  .input-wrapper {
    .paragraph {
      margin: 0;
    }
  }
}
