.link {
  color: $black;
  font-weight: 500;

  &:hover {
    text-decoration: none;
  }
}

.link--orange {
  color: $primary;
}

.link--white {
  color: $white;
}
