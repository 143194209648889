.admin__wrapper-orange {
  background: $primary;
}

.admin__wrapper-border-bottom {
  border-bottom: 1px solid $grayLightest;
}

.admin__wrapper-gray {
  background: $grayLightest;
}

.admin__menu-item {
  color: $white;
  text-decoration: none;
  margin-right: 15px;

  &.active {
    color: $black;
  }
}

.admin__menu-item:hover {
  color: $grayDark;
}

.admin__paragraph-right {
  float: right;
  line-height: 65px;
}

.admin-bar {
  color: $black;

  @media (max-width: $mobileBreakpoint) {
    display: none;
  }

  a {
    margin-left: 10px;
    color: $black;
  }
}

.job--admin {
  .job__content-left {
    flex: 1;
  }

  .job__subtitle a {
    color: $primary;

    &:hover {
      text-decoration: none;
    }
  }
}

.admin__link {
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.admin__textarea-big {
  min-height: 500px;
}
