.top-bar {
  position: relative;
  z-index: 1;
  display: flex;
  width: 100%;
  min-height: 60px;
  font-family: "Poppins", sans-serif;
  font-size: 16px;
  font-weight: 300;
  text-align: center;
  text-decoration: none;
}

.top-bar__item {
  width: 100%;
  padding: 16px;
  background: $yellow;
  color: $black;
  line-height: 28px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: none;

    .underline {
      text-decoration: none;
    }
  }

  &:nth-child(1) {
    background: $greenPrimary;
    color: $black;
  }

  &.top-bar__item--black,
  &:nth-child(2) {
    background: $black;
    color: $white;
  }

  &.top-bar__item--white {
    background: $white;
    color: $black;

    .pill {
      background: $black;
      color: $white;
    }
  }

  &.top-bar__item--freelancers-offer {
    background-color: $black;
    background-image: url("/images/topbar/fire-icon.png"), url("/images/topbar/fire-icon.png");
    background-size: 40px 40px;
    background-repeat: no-repeat, no-repeat;
    background-position: left 200px top 25px, right 200px bottom 25px;
    color: $greenPrimary;

    a {
      text-decoration: none;

      &:hover .underline {
        text-decoration: none;
      }
    }

    .desktop {
      display: inline-block;
    }

    .mobile {
      display: none;
    }

    .mobile-small {
      display: none;
    }

    @media (max-width: 880px) {
      background-image: none;

      .desktop {
        display: none;
      }

      .mobile {
        display: inline-block;
      }
    }

    @media (max-width: 500px) {
      background-image: none;

      .desktop,
      .mobile {
        display: none;
      }

      .mobile-small {
        display: inline-block;
      }
    }

    @media (max-width: 350px) {
      background-position: -30px 0;
    }

    @media (max-width: 315px) {
      background-position: -50px 0;
    }
  }

  &.top-bar__item--merch {
    background: #f1f1ef url("/images/topbar/merch.png");
    background-size: 345px auto;
    background-repeat: no-repeat;

    a {
      text-decoration: none;

      &:hover .underline {
        text-decoration: none;
      }
    }

    .desktop {
      display: inline-block;
    }

    .mobile {
      display: none;
    }

    @media (max-width: 880px) {
      text-align: right;

      .desktop {
        display: none;
      }

      .mobile {
        display: inline-block;
      }
    }

    @media (max-width: 350px) {
      background-position: -30px 0;
    }

    @media (max-width: 315px) {
      background-position: -50px 0;
    }
  }

  &:nth-child(2) {
    @media (max-width: 800px) {
      display: none;
    }
  }

  .pill {
    position: relative;
    top: -2.5px;
  }
}

.top-bar__img {
  position: absolute;
  bottom: -12px;
  left: 50%;
  width: 128px;
  height: 72px;
  margin-left: -64px;

  @media (max-width: 800px) {
    display: none;
  }
}

// .top-bar__item-mobile {
//   display: none;
//   color: $white;

//   @media (max-width: 880px) {
//     display: flex;
//     width: 100%;
//     justify-content: space-between;
//     align-items: center;
//     padding: 0 10px;
//   }
// }
