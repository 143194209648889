.tippy-custom.tippy-box {
  font-family: "Poppins", sans-serif;
  background-color: $black;
  color: $white;
  font-size: 12px;
  font-weight: 300;
  line-height: 18px;
  max-width: 280px;
  pointer-events: none;
  padding: 6px;
  border-radius: 0;

  .tippy-arrow::before {
    color: #000;
  }
}

.tippy-white.tippy-box {
  color: $black;
  background-color: $white;

  .tippy-arrow::before {
    color: $white;
  }
}

.tooltip__button {
  z-index: 1;
  position: relative;
  top: 3px;
  margin-left: 5px;
  display: inline-block;
  width: 18px;
  height: 18px;
  background: url("/images/icons/tooltip-black.svg");
  background-repeat: no-repeat;
  background-size: 18px 18px;
  text-align: left;
  cursor: pointer;
  padding: 0 !important;

  &:hover + .tooltip__element {
    visibility: visible;
  }
}

.tooltip__button--white {
  background: url("/images/icons/tooltip-white.svg");
}

.tooltip__custom-button {
  cursor: pointer;
}

.tooltip__custom-button--cursor-default {
  cursor: default;
}
