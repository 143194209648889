.switcher {
  padding: 5px;
  margin-top: 15px;
  margin-bottom: 15px;
  display: inline-block;
  user-select: none;

  @media (max-width: $mobileBreakpoint) {
    width: 100%;
  }

  &.switcher--freelancers {
    width: 300px;
    display: flex;
    margin: 0 auto;

    .switcher__button {
      width: 50%;
    }
  }
}

.switcher--green {
  background: $greenPrimary;
}

.switcher--dark-gray {
  background: $grayLight;
}

.switcher--orange {
  background: $primary;
}

.switcher--gray {
  background: $grayLightest;
}

.switcher--without-margin {
  margin: 0;
}

.switcher--compact-size {
  .switcher__button {
    width: 135px;
  }
}

.switcher__button {
  display: inline-block;
  font-size: 16px;
  line-height: 22px;
  font-weight: 500;
  padding: 12px 0;
  color: $black;
  text-decoration: none;
  cursor: pointer;
  position: relative;
  width: 300px;
  text-align: center;

  &:not(.active) {
    text-decoration: none;
    background: $grayLightest;
  }

  .pill {
    position: absolute;
    top: 50%;
    right: -50px;
    text-transform: uppercase;
    font-weight: 500;
    margin-top: -9px;

    @media (max-width: 500px) {
      right: 5px;
    }
  }

  &.active {
    background: $black;
    color: $white;
  }

  @media (max-width: $mobileBreakpoint) {
    width: 100% !important;
    font-size: 15px;
  }

  &:not(.active) {
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }
}

.switcher-sticky-wrapper {
  z-index: 10;
  position: sticky;
  top: 10px;
}

.preview-switcher {
  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}
