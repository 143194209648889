.stats {
  @media (max-width: 700px) {
    display: none;
  }
}

.stats--flex {
  display: flex;

  @media (max-width: 940px) {
    display: block;
  }

  @media (max-width: 700px) {
    display: none;
  }
}

.stats--flex--mobile-visible {
  @media (max-width: 700px) {
    display: block;
    margin-bottom: 50px;
  }
}

.stats__item {
  text-align: left;
  display: inline-block;
  width: 33.3%;
  margin-top: 20px;

  @media (max-width: 940px) {
    width: 50%;
  }

  @media (max-width: 700px) {
    width: 100%;
  }

  &.center {
    text-align: center;
  }
}

.stats__item--25 {
  width: 25%;
  padding: 0 15px;

  @media (max-width: 940px) {
    width: 50%;
  }

  @media (max-width: 700px) {
    width: 100%;
    padding: 0;
  }
}

.stats__heading {
  font-family: "Rubik", sans-serif;
  font-size: 36px;
  font-weight: 900;
  color: $primary;
  margin: 0;

  @media (max-width: $mobileBreakpoint) {
    font-size: 28px;
    line-height: 50px;
  }
}

.stats__heading--loading {
  &::before {
    content: " ";
    display: inline-block;
    width: 150px;
    height: 38px;
    background: $primary;

    @media (max-width: 700px) {
      width: 100px;
    }
  }
}

.stats__text {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: $black;
  margin: 0;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.4;
  }
}

.stats__badge {
  background-color: $primary;
  padding: 0 5px;
  font-size: 11px;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  text-transform: uppercase;
  color: $white;
  position: absolute;
  height: 16px;
  line-height: 16px;
  top: 7px;
  margin-left: -8px;
}

.stats__badge--green {
  background-color: $greenPrimary;
  color: $black;
}

@keyframes text-slide {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(-2000px);
  }
}

.stats__carousel {
  position: relative;
  height: 63px;
  overflow: hidden;
}

.stats__carousel-inner {
  position: absolute;
  background: $black;
  width: 4200px;
  animation: text-slide 60s linear infinite alternate;
}

.stats__carousel-text {
  display: inline-block;
  text-align: center;
  font-family: "Rubik", sans-serif;
  font-size: 48px;
  line-height: 63px;
  font-weight: 900;
  text-transform: uppercase;
  color: $white;
  width: 1400px;
}

.stats-companies {
  background: $grayLightest;
}

.stats__detail-wrapper {
  .stats__wrapper {
    margin-top: 60px;

    @media (max-width: 700px) {
      margin-top: 0;
    }
  }
}

.stats-detail {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  margin-top: 90px;

  @media (max-width: 550px) {
    margin-top: 50px;
  }
}

.stats__detail-item {
  width: 33.3%;
  box-sizing: border-box;
  padding: 0 10px;
  margin-bottom: 50px;
  text-align: left;

  @media (max-width: 800px) {
    width: 50%;
  }

  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 25px;
  }
}

.stats__detail-wrapper--category {
  .heading-2 {
    width: 33.3%;
    text-align: left;
  }

  &.white {
    background: $white;
  }

  .stats-detail,
  .stats__wrapper {
    margin-top: 0;
  }

  @media (min-width: 901px) {
    .stats__detail-item {
      width: 50%;
    }
  }

  @media (max-width: 900px) {
    .flex {
      display: block;
    }

    .heading-2 {
      width: 100%;
      margin-bottom: 60px;
    }
  }
}

.stats__left-panel {
  padding-top: 40px;
  width: 325px;
  overflow: hidden;
}

@keyframes banner-slide {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-210px);
  }
}

.stats__right-panel {
  display: flex;
  align-items: center;
}

.stats__link {
  font-family: "Poppins", sans-serif;
  color: $primary;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }

  &:hover {
    text-decoration: none;
  }
}

.stats__full-link {
  border-bottom: 1px dotted $black;
  padding: 10px 0;
  font-weight: 500;

  &.limited {
    display: none;
  }

  &:last-child {
    border-bottom: none;
  }

  a {
    color: $primary;
    white-space: nowrap;
    overflow: hidden;
    display: inline-block;
    text-overflow: ellipsis;
    max-width: 85%;

    &:hover {
      text-decoration: none;
    }
  }

  .stats--show-hide-more {
    color: $black;
    text-decoration: underline;
    cursor: pointer;

    &:hover {
      text-decoration: none;
    }
  }

  span {
    float: right;
    font-weight: 500;
  }
}

.full-list .stats__full-link.limited {
  display: block;
}

.stats__category-link {
  color: $black !important;
  margin: 0 10px;
}
