.show-more {
  position: relative;
  top: 0;
  left: 0;
  color: $white;
  background: $black;
  width: 30px;
  height: 30px;
  font-size: 22px;
  text-align: center;
  line-height: 32px;
  user-select: none;
  cursor: pointer;

  @media (max-width: 700px) {
    position: relative;
    top: 0;
    left: 0;
    margin-bottom: 10px;
  }
}
