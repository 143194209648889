.loader__heading-1 {
  display: inline-block;
  width: 350px;
  height: 38px;
  background: #999;
}

.loader__heading-2 {
  display: inline-block;
  width: 350px;
  height: 38px;
  background: #999;
}

.loader__heading-3 {
  display: inline-block;
  width: 150px;
  height: 25px;
  background: $black;
}

.loader__text {
  display: inline-block;
  width: 130px;
  height: 18px;
  background: $grayLightest;
}

.loader__text--longer {
  width: 230px;
}

.loader__text-full {
  width: 100%;
}

.loader__text--black {
  background: $black;
}

.loader__item-dark-gray {
  background: $graySuperLight !important;
}

.loader__text-gray {
  background: #999;
}

.loader__pill {
  display: inline-block;
  width: 75px;
  height: 26px;
  background: $grayLightest;
  margin-right: 5px;
  margin-bottom: 5px;
}
