.player-wrapper {
  position: relative;
  padding-top: 56.25%;
  overflow: hidden;
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;

  iframe {
    height: 100%;
  }
}
