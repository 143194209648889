.sidebar {
  margin-top: 14px;
}

.sidebar__img {
  width: 100%;
  height: auto;

  &.sidebar__img--desktop {
    max-width: 300px;

    @media (max-width: 880px) {
      display: none;
    }
  }

  &.sidebar__img--mobile {
    display: none;
    max-width: 335px;

    @media (max-width: 880px) {
      display: block;
      margin: 0 auto;
    }
  }
}
