.pill--manifest {
  color: $white !important;
  background: url("/images/manifest/tag@2x.png") no-repeat !important;
  background-size: cover !important;

  &:hover {
    background: $black !important;
    color: $white !important;
  }

  &.pill--small {
    background: $primary !important;
  }
}

.manifest-login__list {
  font-weight: 500;
  line-height: 36px;
}

.manifest-subheader {
  font-family: "Poppins", sans-serif;
  font-size: 30px;
  font-weight: 300;
  line-height: 36px;
  margin-top: 20px;
  margin-bottom: 30px;

  @media (max-width: $mobileBreakpoint) {
    font-size: 20px;
    line-height: 1.3;
  }
}

.manifest-header-text {
  margin-bottom: 30px;
}

.manifest-stats-box {
  background: $black;
  padding: 48px;
  text-align: center;
  margin-bottom: 30px;

  .paragraph {
    margin: 0;
  }

  .paragraph,
  .heading-2-5 {
    color: $white;
  }
}

.heading-2-5.manifest-heading-margin-bottom {
  margin-bottom: 60px;

  @media (max-width: 880px) {
    margin-bottom: 30px;
  }
}

.manifest__list {
  max-width: 850px;
  margin: 0 auto;
  padding-left: 30px;

  li {
    position: relative;
    text-align: left;
    font-size: 20px;
    font-weight: 500;
    list-style: none;
    list-style-type: none;
    padding-left: 10px;

    &::before {
      position: absolute;
      top: 0;
      left: -23px;
      display: inline-block;
      width: 18px;
      margin-right: 8px;
      content: url("https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/svg/1f449.svg");
    }

    @media (max-width: $mobileBreakpoint) {
      font-size: 18px;
    }
  }
}

.manifest-checkbox__heading {
  display: block;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
}

.manifest-checkbox__text {
  display: block;
  font-size: 15px;
  line-height: 24px;
}

.container--manifest {
  background: url("/images/manifest/sidebar-banner@2x.png") no-repeat #fb3421;
  background-size: 510px 674px;
  background-position: bottom 0 right -4px;

  @media (max-width: 1200px) {
    background: $primary;
  }
}

.manifest-login__text {
  max-width: 250px;
}

.manifest-microsite__header-wrapper {
  background: #fb3421;
  overflow: hidden;
}

.manifest-microsite__header {
  position: relative;
  padding-top: 40px;
  padding-bottom: 40px;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: -225px;
    display: block;
    width: 1440px;
    height: 100%;
    background: url("/images/manifest/microsite-1@2x.png") no-repeat #fc3622;
    background-size: cover;
    background-position: top 0 right 0;

    @media (max-width: 1050px) {
      background: $primary;
    }
  }
}

.manifest-microsite__header-content {
  position: relative;
  max-width: 550px;

  @media (max-width: 1050px) {
    max-width: 100%;
  }
}

.manifest-microsite__main-bg {
  background: #fb3421;
  overflow: hidden;
  min-height: 645px;

  h3,
  p {
    position: relative;
  }

  h3 {
    padding-top: 60px;
    margin-bottom: 40px !important;
  }
}

.manifest-microsite__main-bg-inner {
  position: relative;

  &::before {
    content: " ";
    position: absolute;
    top: 0;
    right: -130px;
    display: block;
    width: 1440px;
    height: 654px;
    background: url("/images/manifest/microsite-2@2x.png") no-repeat #fe331f;
    background-size: cover;
    background-position: top 0 right 0;

    @media (max-width: 1100px) {
      background: $primary;
    }
  }
}

.manifest-microsite__list-container {
  max-width: 800px;
  margin: 0;
}

.manifest-microsite__list-cta {
  @media (max-width: 880px) {
    margin-top: 30px;
    margin-bottom: 60px;
  }
}

.manifest-microsite__join-heading {
  margin-bottom: 30px;
}

.manifest-microsite__join-subheading {
  max-width: 570px;
  margin: 0 auto;
}

.manifest-video-container {
  position: relative;
  padding-bottom: 56.25%;
}

.manifest-video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
