.modal {
  z-index: 2000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $white;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

.modal__header-left {
  flex: 1;
  flex-grow: 1;
  padding-left: 30px;
  padding-right: 15px;
  display: flex;
  align-items: center;

  @media (max-width: $mobileBreakpoint) {
    padding-left: 24px;
  }
}

.modal__header-right {
  display: flex;
  align-items: center;
  text-align: right;
  padding-left: 15px;
  padding-right: 30px;

  @media (max-width: 1200px) {
    display: none;
  }
}

.modal__header-close {
  display: block;
  float: right;
  position: relative;
  background: none;
  outline: none;
  user-select: none;
  appearance: none;
  height: 80px;
  padding-left: 10px;
  cursor: pointer;

  .button__in {
    display: block;
    float: right;
    position: relative;
    background: $grayLightest;
    color: $black;
    text-decoration: none;
    outline: none;
    user-select: none;
    appearance: none;
    top: 30px;
    right: 30px;
    font-size: 36px;
    font-weight: 300;
    padding: 0 19px;
    line-height: 58px;
  }

  .button__in-icon {
    display: inline-block;
    transform: rotate(45deg);
  }

  &:hover {
    .button__in {
      top: 22px;
      right: 22px;
    }
  }
}

.modal__header-close--fixed {
  z-index: 1000;
  position: fixed;
  top: 0;
  right: 0;
}

.modal__header-close--absolute {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 0;
}

.modal__header-close--absolute-container {
  z-index: 1000;
  position: absolute;
  top: 0;
  right: 20px;

  @media (max-width: $mobileBreakpoint) {
    right: 0;
  }

  .button__in {
    top: 20px;
    right: 10px;

    @media (max-width: $mobileBreakpoint) {
      top: 10px;
      right: 10px;
    }
  }

  &:hover {
    .button__in {
      top: 12px;
      right: 2px;

      @media (max-width: $mobileBreakpoint) {
        top: 0;
        right: 0;
      }
    }
  }
}

.modal__header .paragraph {
  color: $white;
  font-size: 16px;
  font-weight: 500;
  margin: 0;

  span {
    color: #999;
    font-weight: 300;
  }

  .padding-left {
    padding-left: 10px;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
  }
}

.modal__cta-note {
  text-align: center;
  margin-top: 16px;

  a,
  .underline {
    color: $black;
    font-weight: 300;
  }
}

.modal__job-preview {
  display: flex;
  gap: 16px;
  background: $grayLightest;
  padding: 16px;

  .job__logo-wrapper {
    margin-right: 0;
  }

  .job__logo {
    top: 0;
  }

  .job__content-left {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  @media (max-width: 880px) {
    .job__logo-wrapper {
      display: flex;
      margin-bottom: 0;
      width: 60px;
    }
  }
}
