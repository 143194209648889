.filter__fullwidth-toggle-button {
  display: none;

  @media (max-width: 1000px) {
    display: block;
  }
}

.filter {
  display: none;
  padding-bottom: 30px;
  background: $grayLightest;
  user-select: none;

  .paragraph.margin-top-small {
    margin-top: 25px;
  }

  @media (max-width: 700px) {
    padding-bottom: 23px;
    padding-top: 23px;

    .container {
      padding: 0;
    }

    .paragraph {
      padding: 0 30px;
    }
  }
}

.active-filter-summary-label {
  position: relative;
  top: 3px;
}

.filter-padding-top {
  padding-top: 30px;
}

.filter--visible {
  display: block;
}

.filter__icon {
  position: relative;
  top: -2px;
  left: 5px;
}

.filter-handler--open {
  .filter__icon {
    transform: rotateX(180deg);
  }
}

.filter__icon--open {
  transform: rotateX(180deg);
}

.filter__searched-container {
  margin-top: 20px;
  white-space: nowrap;
  padding: 0 30px;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  height: 100%;
  min-height: 45px;

  .pill {
    display: inline-block;
  }

  @media (max-width: 800px) {
    padding: 0 20px;
  }
}

.fiter__positions-wrapper {
  @media (max-width: 700px) {
    white-space: nowrap;
    padding: 0 30px;
    -webkit-overflow-scrolling: touch;
    overflow-y: scroll;
  }
}

.filter__stats {
  font-size: 16px;
  font-weight: 500;
  color: $black;
  display: inline-block;
  line-height: 1.7;
  margin-left: 15px;
  margin-top: 8px;

  @media (max-width: 1000px) {
    display: block;
    margin: 0;
    margin-bottom: 10px;
  }

  @media (max-width: $mobileBreakpoint) {
    font-size: 15px;
    line-height: 1.6;
  }
}

.filter__reset {
  cursor: pointer;
  overflow: hidden;
  height: 40px;
  margin-bottom: 5px;

  &:hover {
    a {
      text-decoration: none;
    }
  }

  a {
    color: $black;
    white-space: nowrap;
  }
}

.filter__reset-icon {
  transform: rotate(45deg);
  font-size: 36px;
  margin-right: 4px;
  display: inline-flex;
  user-select: none;
  color: $black;
}

.filter__toggle {
  white-space: nowrap;
  float: right;
  line-height: 46px;
}

.filter__inside-search-wrapper {
  display: flex;
  justify-content: space-between;
}

.filter__right-companies {
  float: right;

  @media (max-width: 1000px) {
    display: none;
  }
}

.filter__inside-search-right {
  margin-top: 6px;

  .pill {
    min-width: 84px;
    text-align: center;
  }

  @media (max-width: 1000px) {
    display: none;
  }
}

.filter__inside-search-right-freelancers {
  margin-top: 20px;
  margin-right: 30px;

  @media (max-width: 1000px) {
    display: none;
  }
}

.filter__inside-search {
  margin-top: 6px;
  white-space: nowrap;
  padding: 0;
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  height: 100%;
  min-height: 45px;

  .pill {
    display: inline-block;
  }
}

.filter__stats-wrapper {
  padding-top: 20px;
}

.filter__extra-wrapper {
  display: flex;
  justify-content: space-between;
  padding: 3px 0 8px;

  @media (max-width: 800px) {
    flex-direction: column;
  }
}

.filter__extra-wrapper--similar {
  .filter__newsletter-link {
    margin-top: 4px;
  }
}

.filter__newsletter-link {
  margin: 0;
  margin-left: auto;

  @media (max-width: $mobileBreakpoint) {
    display: none;
  }

  @media (max-width: 800px) {
    margin-left: 0;
  }

  span {
    font-weight: 500;
    text-decoration: underline;
  }

  &:hover {
    cursor: pointer;

    span {
      text-decoration: none;
    }
  }
}

.filter__newsletter-link--desktop {
  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}

.filter__tabs {
  @media (max-width: 880px) {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.filter__tabs-container {
  -webkit-overflow-scrolling: touch;
  overflow-x: auto;
  padding-top: 8px;
  margin-top: -8px;
  white-space: nowrap;
}

.filter__tabs-list {
  display: flex;
  align-items: center;
}

.filter__options-transparent-overlay {
  position: fixed;
  top: 400px;
  left: 0;
  right: 0;
  height: 100%;
  z-index: 1;
  background: transparent;

  @media (max-width: 1140px) {
    display: none;
  }
}

.filter__options-absolute-wrapper {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 2;
  box-shadow: 0 5000px 0 5000px rgba(0, 0, 0, 0.5);

  @media (max-width: 1140px) {
    display: none;
  }
}

.filter__options-mobile-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 103;
  background: $grayLightest;

  @media (min-width: 1140px) {
    display: none;
  }
}

.mobile-filter-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.mobile-filter-options-wrapper {
  height: 100%;
  overflow-y: auto;
  padding: 0 20px;
}

.filter-options-modal-header {
  padding-left: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filter-options-modal-footer {
  padding: 20px;
}

.filter-salary-range-wrapper {
  @media (min-width: 1140px) {
    height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}

.filter-salary-wrapper {
  display: flex;
  align-items: center;
  gap: 24px;

  @media (max-width: 1140px) {
    flex-direction: column;
    align-items: normal;
  }
}

.filter-salary-column {
  justify-content: space-between;
  display: flex;
  flex-direction: column;

  @media (min-width: 1140px) {
    height: 80px;
  }
}

.filter-salary-currency-select-wrapper {
  width: 200px;

  @media (max-width: 1140px) {
    width: 100%;
  }
}

.filter-salary-currency-select {
  height: 40px;
  border: 0;
  padding: 8px;
}

.filter-summary-count {
  color: $grayLight;
  font-weight: 500;
  margin-left: 4px;
}
