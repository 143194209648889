.notification {
  z-index: 100;
  position: sticky;
  top: 0;
  text-align: center;
  padding: 15px 30px;
  background: $yellow;
  color: $black;

  .paragraph {
    margin: 0;
    font-weight: 500;
  }

  a {
    color: $black;
  }
}

.notification--error {
  background: $primary;
  color: $black;
}

.notification--pink {
  background: $pink;
  color: $black;
}

.notification--green {
  background: $greenPrimary;
  color: $black;
}

.notification--gradient-primary {
  background: $primaryGradient;
  color: $black;
}

.notification--fixed {
  position: fixed;
  width: 100%;
}

.notification--relative {
  position: relative;

  .container {
    position: relative;
  }
}

.notification--in-modal {
  z-index: 1001;
  padding: 27px 30px;
}

.notification__close {
  position: absolute;
  top: -4px;
  right: 0;
  font-size: 25px;
  transform: rotate(45deg);
  cursor: pointer;
}

.notification--mobile-hidden {
  @media (max-width: $mobileBreakpoint) {
    display: none;
  }
}
