.banner {
  display: none;
  z-index: 100;
  position: fixed;
  left: 20px;
  bottom: 20px;
  border-radius: 4px;
  width: 500px;
  height: 140px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  overflow: hidden;

  &.banner--visible {
    display: block;
  }

  @media (max-width: 700px) {
    display: none !important;
  }
}

.banner__bg {
  height: 100%;
  background-size: cover;
  cursor: pointer;
}

@keyframes pulse {
  0% { transform: scale(1); }
  50% { transform: scale(3.5); }
  100% { transform: scale(1); }
}

.banner__content {
  // position: relative;
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.banner__left-panel {
  padding-top: 30px;
  width: 360px;
  overflow: hidden;
}

@keyframes banner-slide {
  0% {
    transform: translateY(0);
  }

  100% {
    transform: translateY(-210px);
  }
}

.banner__banner-carousel {
  animation: banner-slide 15s linear infinite alternate;
}

.banner__right-panel {
  display: flex;
  align-items: center;
  max-width: 130px;
}

.banner__heading {
  font-family: "Rubik", sans-serif;
  font-size: 33px;
  font-weight: 900;

  // color: $primary;
  color: $white;
  margin: 0;
}

.banner__text {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  color: $white;
  margin: 0;
}

.banner__link {
  font-family: "Poppins", sans-serif;
  color: $primary;
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
}

.banner__banner-item {
  width: 100%;
  box-sizing: border-box;
  padding: 0 30px;
  margin-bottom: 25px;
  text-align: left;

  .banner__heading {
    font-size: 30px;
  }

  .banner__text {
    font-size: 15px;
  }
}

.banner__close {
  position: absolute;
  top: 0;
  right: 13px;
  display: inline-block;
  transform: rotate(45deg);
  font-size: 25px;
  cursor: pointer;
  color: $white;

  &:hover {
    color: $primary;
  }
}
