.autocomplete__single-value .company-input__label-subtitle {
  display: none;
}

.company-input__label-title {
  font-weight: 500;
  font-size: 0.9rem;
}

.company-input__label-subtitle {
  font-size: 0.9rem;
  color: #808080;
}
