.payment__flex-wrapper {
  display: flex;
  position: relative;
  z-index: 1;

  @media (max-width: 880px) {
    flex-direction: column;
  }

  @media (max-width: $mobileBreakpoint) {
    &.margin-top {
      margin-top: 40px;
    }
  }
}

.payment__content-left {
  flex: 1;
  padding-right: 30px;
  padding-top: 20px;

  @media (max-width: 880px) {
    padding-right: 0;
  }
}

.payment__content-right {
  width: 360px;
  margin-top: 105px;
  max-width: 100%;

  @media (max-width: $mobileBreakpoint) {
    & {
      margin-top: 40px;
      padding-right: 0;
    }
  }
}

.payment__content-right-submit {
  margin-top: 25px;
}
